export default {
  methods: {
    formatPrice(value) {
      return value !== null
        ? this.$optix.utils.currencyFormat(value).replace(/\.00$/, "")
        : null;
    },
    /**
     * For resource list
     */
    getPriceRange(resource, isDaily) {
      if (isDaily && this.getPriceRange(resource, false) === "Free") {
        return null;
      }

      const resourceField = isDaily ? "price_day" : "price_hour";
      const priceField = isDaily ? "price_max" : "price";

      let values = [];

      // Consider the default price
      let value;
      if (!isDaily) {
        value = resource[resourceField] > 0 ? resource[resourceField] : 0;
      } else {
        value =
          resource.price_hour > 0 && resource[resourceField] > 0
            ? resource[resourceField]
            : null;
      }
      if (value !== null) {
        values.push(value);
      }

      // And all conditional prices with monetary units ($)
      resource.conditional_pricing?.forEach((c) => {
        c.prices.forEach((price) => {
          if (price.unit.type !== "CURRENCY") {
            return;
          }

          let value;
          if (!isDaily) {
            value = price[priceField] > 0 ? price[priceField] : 0;
          } else {
            value =
              price.price > 0 && price[priceField] > 0
                ? price[priceField]
                : null;
          }
          if (value !== null) {
            values.push(value);
          }
        });
      });

      if (!values.length) {
        return null;
      }

      values = values.sort((a, b) => a - b);

      const first = this.formatPrice(values[0]);
      const last = this.formatPrice(values[values.length - 1]);

      if (first === last) {
        if (values[0] > 0) {
          return first;
        } else {
          return isDaily ? null : "Free";
        }
      } else {
        return first + "–" + last;
      }
    },
    /**
     * For resource profile
     */
    getResourcePrices(resource) {
      const result = [];
      const that = this;

      // Common logic for both default & conditional prices
      const pushPrice = function(name, hourly, daily) {
        const hourlyPrice = hourly > 0 ? that.formatPrice(hourly) : "Free";
        const dailyPrice =
          hourly > 0 && daily > 0 ? that.formatPrice(daily) : null;

        result.push({
          name: name,
          hourly_price: hourlyPrice,
          daily_price: dailyPrice,
        });
      };

      if (resource.price_hour !== null || resource.price_day !== null) {
        pushPrice("Default", resource.price_hour, resource.price_day);
      }

      resource.conditional_pricing.forEach((c) => {
        c.prices.forEach((price) => {
          if (
            (price.price !== null || price.price_max !== null) &&
            price.unit.type === "CURRENCY"
          ) {
            pushPrice(c.name, price.price, price.price_max);
          }
        });
      });

      // Hide "Default" label when there are no conditional prices
      if (result.length === 1 && result[0].name === "Default") {
        result[0].name = null;
      }

      return result;
    },
  },
};
