<script>
export default {
  methods: {
    async checkPasswordStrength(field, password) {
      const {
        data: {
          data: { userPasswordStrength: result },
        },
      } = await this.$optix.ws.graphQL(
        `query userPasswordStrength($password: String!) {
          userPasswordStrength(password: $password) {
            is_strong
            message
          }
        }`,
        {
          password,
        }
      );
      if (!result.is_strong) {
        // Show the error on the form
        this.addFormFieldError(field, result.message);
        // Reject the promise
        throw new Error(result.message);
      }
    },
  },
};
</script>
