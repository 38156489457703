<template>
  <layout-content
    class="resource"
    :class="{ picker: $route.name == 'resource-picker' }"
  >
    <o-layout row v-if="loading">
      <div class="room-details left-side" v-if="showLeft">
        <o-layout column class="main-image">
          <!-- Picture -->
          <!-- Note: Vuetify requires a bad src to force the placeholder -->
          <v-img :aspect-ratio="240 / 170" width="100%" src="#">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <skeleton-loader radius="4" />
              </v-row>
            </template>
          </v-img>
          <!-- Title -->
          <h1 class="mb-3">
            <skeleton-loader width="60%" :height="20" radius="10" />
          </h1>
          <!-- Type and capacity -->
          <h3>
            <skeleton-loader width="30%" :height="20" radius="10" />
          </h3>
        </o-layout>
      </div>
      <!-- Time selection -->
      <div
        class="date-selection right-side"
        :class="{ solo: !showLeft }"
        v-if="showRight"
      >
        <!-- Month picker / open calendar picker -->
        <h1 key="month-picker" class="month-picker">
          <skeleton-loader width="30%" :height="26" radius="10" />
        </h1>
        <!-- Horizontal day picker -->
        <div key="day-picker" class="day-picker">
          <skeleton-loader width="100%" :height="54" radius="4" />
        </div>
        <!-- Duration picker -->
        <div key="duration-picker">
          <h2>
            <skeleton-loader width="30%" :height="26" radius="10" />
          </h2>
          <div>
            <skeleton-loader :height="40" radius="4" />
          </div>
        </div>
        <!-- Time picker -->
        <div key="time-picker" class="time-picker">
          <h2>
            <skeleton-loader width="30%" :height="26" radius="10" />
          </h2>
          <div v-if="loadingTime">
            <skeleton-loader
              v-for="n in 6"
              :key="'skeleton' + n"
              height="48"
              radius="4"
              class="mb-2"
            />
          </div>
        </div>
      </div>
    </o-layout>
    <o-layout row v-if="!loading">
      <!-- room details -->
      <div class="room-details left-side" v-if="showLeft">
        <o-layout column class="main-image">
          <!-- Picture -->
          <v-carousel
            v-if="images"
            :show-arrows="images.length > 1"
            height="auto"
            hide-delimiter-background
            delimiter-icon="optixicon-circle"
            class="carousel-container"
          >
            <v-carousel-item
              v-for="image in images"
              :key="image.key"
              :aspect-ratio="240 / 170"
              width="100%"
              :src="image.src"
              :srcset="image.srcset"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <skeleton-loader radius="4" />
                </v-row>
              </template>
            </v-carousel-item>
          </v-carousel>
          <!-- Title -->
          <h1>
            <div class="share-button text-button-container">
              <o-btn text @click="copyShareLink">Share</o-btn>
            </div>
            {{ resource.title }}
          </h1>
          <!-- Type and capacity -->
          <h3>
            {{ resource.type.name }}
            <svg class="font-themed-svg" width="4" height="7">
              <circle
                cx="2"
                cy="2"
                r="2"
                :fill="$optix.theme.darkMode ? '#fff' : '#000'"
              />
            </svg>
            {{ resource.capacity }}
            {{ resource.capacity > 1 ? "people" : "person" }}
          </h3>
          <!-- Prices -->
          <div class="prices">
            <div class="price" v-for="(price, index) in prices" :key="index">
              <div class="price-name" v-if="price.name">{{ price.name }}</div>
              <div v-if="price.hourly_price">
                {{
                  price.hourly_price +
                    (price.hourly_price !== "Free" ? "/hour" : "")
                }}
              </div>
              <div v-if="price.daily_price">{{ price.daily_price }}/day</div>
            </div>
          </div>
          <!-- Description -->
          <div class="description" v-if="resource.description">
            <expandable-description :description="resource.description" />
          </div>
          <div class="tour-link" v-if="showTourLink">
            <tour-link />
          </div>
          <!-- Amenities -->
          <div
            class="amenities"
            v-if="resource.amenities && resource.amenities.length > 0"
          >
            <h5>Amenities</h5>
            <div class="list">
              <div
                class="amenity"
                v-for="amenity in amenityList"
                :key="amenity.amenity_id"
                :title="amenity.description"
              >
                <div class="name">{{ getNameForAmenity(amenity) }}</div>
              </div>
            </div>
          </div>
          <!-- Location -->
          <div class="location">
            <h5>Location</h5>
            <p>
              <address-description :location="location" />
            </p>
            <a :href="locationMapLink" target="_blank">
              <img
                v-if="locationMapUrl()"
                :src="locationMapUrl()"
                :srcset="locationMapUrl(2) + ' 2x,' + locationMapUrl(3) + ' 3x'"
              />
            </a>
          </div>
          <!-- Cancellation policy -->
          <div
            class="cancellation-policy"
            v-if="organization.drop_in.cancellation_policy"
          >
            <h5>Cancellation policy</h5>
            <p>{{ organization.drop_in.cancellation_policy }}</p>
          </div>
          <div v-if="allowChangeDetails" class="book-now">
            <div class="button-wrapper">
              <base-button @click="openPicker">Continue</base-button>
            </div>
          </div>
        </o-layout>
      </div>
      <!-- Time selection -->
      <div
        class="date-selection right-side"
        :class="{ solo: !showLeft }"
        v-if="showRight"
        @click="showDurationPicker = false"
      >
        <!-- Month picker / open calendar picker -->
        <h1
          key="month-picker"
          class="month-picker clickable"
          @click="toggleDatePicker"
        >
          {{ month }}
          <v-icon v-if="!showDatePicker">mdi-menu-down</v-icon>
          <v-icon v-if="showDatePicker">mdi-menu-up</v-icon>
        </h1>
        <!-- Calendar picker -->
        <div
          key="calendar-picker"
          class="calendar-picker"
          v-if="showDatePicker"
        >
          <o-date-picker
            v-model="filterInitialDate"
            ref="calendarDatePicker"
            :min="currentDatetime.toISO().substr(0, 10)"
          />
        </div>
        <!-- Horizontal day picker -->
        <div key="day-picker" class="day-picker" v-if="!showDatePicker">
          <scrollable-date-picker
            v-model="filterInitialDate"
            :maxLength="pickerMaxLength"
            :min="currentDatetime.toISO().substr(0, 10)"
          />
        </div>
        <!-- Duration picker -->
        <div
          key="duration-picker"
          class="duration-picker"
          v-if="!showDatePicker && !selectByTimeslot"
        >
          <h2>How long?</h2>
          <div class="outlined">
            <div
              class="option"
              :class="{ selected: option.selected }"
              v-for="option in durationOptions"
              :key="option.time"
              @click.stop="selectDuration(option, $event)"
            >
              {{ option.time }}
              <v-icon
                class="default-font-color"
                v-if="option.isMore && !showDurationPicker"
                >mdi-menu-down</v-icon
              >
              <v-icon
                class="default-font-color"
                v-if="option.isMore && showDurationPicker"
                >mdi-menu-up</v-icon
              >
            </div>
          </div>
        </div>
        <!-- Time picker -->
        <div key="time-picker" class="time-picker" v-if="!showDatePicker">
          <h2 v-if="selectByTimeslot">What time?</h2>
          <h2 v-if="!selectByTimeslot">Starting when?</h2>
          <div v-if="loadingTime">
            <skeleton-loader
              v-for="n in 6"
              :key="'skeleton' + n"
              height="48"
              radius="4"
              class="mb-2"
            />
          </div>
          <div
            key="no-times"
            v-if="this.resource.timeblocks.length == 0 && !loadingTime"
            class="no-times"
          >
            <o-icon icon="o-icon-clock" />
            <div>Sorry, no times available. Try changing the day.</div>
          </div>
          <div
            v-if="!loadingTime && durationPicker.showStart"
            class="fade top"
          ></div>
          <div
            v-if="!loadingTime"
            class="time-options"
            ref="durationPicker"
            @scroll="handleScroll"
            :style="{ 'max-height': durationPickerMaxHeight }"
          >
            <template v-if="!selectByTimeslot">
              <base-button
                v-for="timeblock in this.resource.timeblocks"
                :key="timeblock.start_timestamp"
                :outlined="true"
                :monocrome="true"
                class="not-bold"
                @click="selectTime(timeblock)"
                >{{ formatTime(timeblock.start_timestamp) }}</base-button
              >
            </template>
            <template v-if="selectByTimeslot">
              <base-button
                v-for="timeblock in this.resource.timeblocks"
                :key="timeblock.start_timestamp"
                :outlined="true"
                :monocrome="true"
                class="timeslot"
                @click="
                  selectTime(
                    timeblock,
                    timeblock.start_timestamp + timeblock.max_length,
                    timeblock.max_length
                  )
                "
              >
                <span class="time">
                  {{ formatTime(timeblock.start_timestamp) }} -
                  {{
                    formatTime(timeblock.start_timestamp + timeblock.max_length)
                  }}
                </span>
                <span class="name" v-if="timeblock.name">
                  {{ timeblock.name }}
                </span>
              </base-button>
            </template>
          </div>
          <div v-if="!loadingTime && durationPicker.showEnd" class="fade"></div>
        </div>
      </div>
    </o-layout>
    <!-- Duration picker menu -->
    <v-menu
      v-model="showDurationPicker"
      :position-x="durationPickerX"
      :position-y="durationPickerY"
      absolute
      offset-y
      :close-on-click="false"
    >
      <v-list max-height="225">
        <v-list-item
          v-for="(item, index) in durationPickerList"
          :key="index"
          @click="selectCustom(item)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </layout-content>
</template>

<script>
import _sortBy from "lodash/sortBy";
import OptixV2Service from "@/services/OptixV2Service";
import PricesMixin from "@/mixins/PricesMixin";
import queryString from "query-string";
import { DateTime } from "luxon";
import { mapGetters, mapMutations, mapState } from "vuex";
import { mapSetter } from "@/vuex/utils";

export default {
  mixins: [PricesMixin],
  data() {
    return {
      loading: true,
      loadingTime: true,
      resource: {},
      prices: [],
      customDuration: {},
      showDurationPicker: false,
      durationPickerX: null,
      durationPickerY: null,
      lastQuery: null,
      currentDatetime: null,
      showDatePicker: false,
      showResourceDetails: true,
      durationPicker: {
        showStart: false,
        showEnd: false,
        maxHeight: "500px",
      },
    };
  },
  /* Created */
  created() {
    this.currentDatetime = DateTime.local();
  },
  /* Mounted */
  mounted() {
    this.$optix.env.access_token = null;
    this.showResourceDetails = !this.$route.meta.picker;
    this.loading = true;
    this.loadingTime = true;
    if (this.$optix.env.get.duration) {
      this.setFilterInitialDuration(this.$optix.env.get.duration);
    } else {
      this.setFilterInitialDuration(null);
    }
    if (this.$optix.env.get.date) {
      this.setFilterInitialDate(this.$optix.env.get.date);
    }
    // Time to load this resource!
    this.loadResource()
      .then(() => {
        this.selectLocation(this.location);
        this.loading = false;

        // Select the predefined duration
        const durationOption = this.durationOptions.find(
          (el) => el.duration == this.filtersUsed.initialDuration
        );
        const durationPickerOption = this.durationPickerList.find(
          (el) => el.value == this.filtersUsed.initialDuration
        );
        if (durationOption) {
          this.selectDuration(durationOption);
        } else if (durationPickerOption) {
          this.selectCustom(durationPickerOption);
        } else {
          // Ensure that only the allowed resource times are selected
          this.setFilterInitialDuration(this.durationOptions[0].duration);
        }
      })
      .catch(() => {
        this.appendSnackbarError({ message: "The resource is not available" });
        this.$router.push({ name: "find" });
      });
  },
  /* Watchers */
  watch: {
    // Watch in case someone changes the filters
    filtersUsed(newFilters) {
      let lastQuery = JSON.stringify({
        initialDate: newFilters.initialDate,
        locations: newFilters.locations,
      });
      if (lastQuery == this.lastQuery) {
        this.scoreResources();
        return;
      }
      this.loadingTime = true;
      this.loadResource();
      this.lastQuery = lastQuery;
    },
    "$route.path": function() {
      this.showResourceDetails = !this.$route.meta.picker;
    },
  },
  /* Computed properties */
  computed: {
    ...mapGetters(["filtersUsed"]),
    ...mapState(["availableLocations", "organization", "mode"]),
    ...mapSetter(mapState(["filterInitialDate"]), {
      filterInitialDate(payload) {
        this.setFilterInitialDate(payload);
        this.showDatePicker = false;
      },
    }),
    amenityList() {
      if (!this.resource.amenities) {
        return [];
      }
      return _sortBy(this.resource.amenities, ["name"]);
    },
    allowChangeDetails() {
      return this.isSmallScreen;
    },
    isSmallScreen() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    showLeft() {
      return this.showResourceDetails;
    },
    showRight() {
      return !this.isSmallScreen || !this.showResourceDetails;
    },
    selectByTimeslot() {
      return (
        this.resource &&
        this.resource.type &&
        this.resource.type.time_selection_type == "TIME_SLOTS"
      );
    },
    showTourLink() {
      return this.organization && this.organization.tours;
    },
    intermediateSize() {
      return (
        this.$vuetify.breakpoint.width < 985 &&
        this.$vuetify.breakpoint.width >= 800 &&
        this.showLeft
      );
    },
    pickerMaxLength() {
      return this.intermediateSize ? 5 : 7;
    },
    images() {
      if (!this.resource) {
        return false;
      }
      if (!this.resource.images) {
        return false;
      }
      if (this.resource.images.length === 0) {
        return false;
      }
      return this.resource.images.map(({ url }, index) => ({
        key: index,
        src: this.$optix.utils.resizedImage(url, 500),
        srcset: this.$optix.utils.resizedImage(
          url,
          500,
          false,
          false,
          "srcset"
        ),
      }));
    },
    /**
     * Handy datetime object for the filter selected, considering the proper timezone
     */
    filterDateTime() {
      return DateTime.fromISO(
        `${this.filtersUsed.initialDate}T${this.filtersUsed.initialTime}:00`,
        { zone: this.location.timezone }
      );
    },
    /**
     * Title filter for month (do not show year if selected year is the current year)
     */
    month() {
      if (this.filterDateTime.year == this.currentDatetime.year) {
        return this.filterDateTime.toFormat("MMMM");
      }
      return this.filterDateTime.toFormat("MMMM, yyyy");
    },
    /**
     * The current location object
     */
    location() {
      let indexed = {};
      this.availableLocations.map((location) => {
        indexed[location.location_id] = location;
      });
      return indexed[this.resource.location_id];
    },
    /**
     * Google maps things
     */
    locationMapLink() {
      if (this.location.latitude && this.location.longitude) {
        return (
          "https://www.google.com/maps/place/" +
          this.location.latitude +
          "," +
          this.location.longitude
        );
      }
      return null;
    },
    /**
     * Generate all durations possible for this resource
     */
    durationPickerList() {
      let durations = [];
      let min = this.resource.min_booking_duration_sec || 15 * 60;
      let max = this.resource.max_booking_duration_sec || 24 * 60 * 60; // For now, only allow max 24h booking
      let step = 15 * 60;
      for (let len = min; len <= max; len += step) {
        durations.push({
          value: len,
          text: this.durationLabel(len),
        });
      }
      return durations;
    },
    /**
     * Generate up to 3 durations to be listed at the duration selector
     */
    predefinedDurations() {
      let durations = [];
      let min = Math.max(
        this.resource.min_booking_duration_sec || 30 * 60,
        30 * 60
      );
      let max = this.resource.max_booking_duration_sec || 24 * 60 * 60; // For now, only allow max 24h booking
      let step = 30 * 60;
      if (max - min < 15 * 60 * 3) {
        // Makes 15 min intervals
        step = 15 * 60;
      }
      for (let len = min, num = 0; len <= max && num < 4; len += step, num++) {
        durations.push({
          duration: len,
          time: this.durationLabel(len),
        });
      }
      return durations;
    },
    /**
     * Generate up to 4 entries for duration selector
     */
    durationOptions() {
      if (this.$optix.env.get.duration) {
        const basicDurations = [
          {
            duration: this.$optix.env.get.duration,
            time: this.durationLabel(this.$optix.env.get.duration),
            selected:
              this.filtersUsed.initialDuration == this.$optix.env.get.duration,
          },
        ];
        if (this.predefinedDurations.length > 1) {
          basicDurations.push({
            duration: "custom",
            time: this.customDuration.time || "More",
            selected: this.customDuration.duration > 0,
            isMore: true,
          });
        }
        return basicDurations;
      }
      let durations = this.predefinedDurations.map((duration) => {
        duration.selected =
          duration.duration == this.filtersUsed.initialDuration;
        return duration;
      });
      let long = false;
      let limit = this.intermediateSize ? 2 : 3;
      if (durations.length > limit) {
        long = true;
        durations = durations.slice(0, limit);
      }
      if (this.customDuration.time || long) {
        durations.push({
          duration: "custom",
          time: this.customDuration.time || "More",
          selected: this.customDuration.duration > 0,
          isMore: true,
        });
      }
      return durations;
    },
    durationPickerMaxHeight() {
      // 48 = powered by bar
      return (
        this.$vuetify.breakpoint.height -
        (this.mode == "fullscreen" ? 420 + 48 : 335 + 48) +
        "px"
      );
    },
    filters() {
      let filters = {};
      Object.assign(filters, this.filtersUsed);
      filters.initialTime = "00:00";
      return filters;
    },
  },
  /* Methods */
  methods: {
    ...mapMutations([
      "setFilterInitialDuration",
      "setFilterInitialDate",
      "selectResource",
      "selectLocation",
      "appendSnackbarError",
    ]),
    /**
     * Load resource
     */
    async loadResource() {
      this.resource = await OptixV2Service.fetchResource(
        this.$route.params.resource_id,
        this.filters
      );
      this.prices = this.getResourcePrices(this.resource);
      await this.scoreResources();
      this.loadingTime = false;
      setTimeout(() => {
        this.handleScroll();
      }, 20);
    },
    /**
     * Score resources (just one) and generate all timeblocks
     */
    async scoreResources() {
      let resources = [this.resource];
      return await OptixV2Service.scoreResources(resources, this.filters, {
        timeblocksForDayOnly: true,
      });
    },
    locationMapUrl(scale) {
      if (this.location.latitude && this.location.longitude) {
        let icon =
          "icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected.png";
        let googleScale = 1;
        switch (scale) {
          case 2:
            googleScale = 2;
            icon =
              "scale:2|icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected_2x.png";
            break;
          case 3:
            googleScale = 4;
            icon =
              "scale:4|icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected_3x.png";
        }
        return (
          "https://maps.googleapis.com/maps/api/staticmap?" +
          queryString.stringify({
            key: this.$optix.env.getConf("google_key"),
            size: "600x400",
            zoom: 15,
            markers: `${icon}|${this.location.latitude},${this.location.longitude}`,
            scale: googleScale,
          })
        );
      }
      return null;
    },
    getNameForAmenity(amenity) {
      let name = amenity.name.replace(/\/ /g, "/");
      // I know, its a nasty fix! Amenities should not have names changed on DB without fixing mobile apps and dashboard
      if (name.match(/Print/)) {
        return "Print/Scan";
      }
      return name;
    },
    /**
     * Format time
     */
    formatTime(timestamp) {
      if (timestamp) {
        return DateTime.fromSeconds(timestamp, {
          zone: this.location.timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE);
      }
      return "";
    },
    durationLabel(len) {
      let label = len / 60 + (this.intermediateSize ? "m" : " mins");
      if (len > 90 * 60) {
        let hour = Math.floor(len / 60 / 60);
        label = hour + "h " + (len - hour * 60 * 60) / 60 + "m";
      }
      return label;
    },
    /**
     * Select a new duration
     */
    selectDuration(duration, event) {
      if (duration.duration != "custom") {
        this.setFilterInitialDuration(duration.duration);
        this.customDuration.time = null;
        this.customDuration.duration = null;
        this.showDurationPicker = false;
      } else if (!this.showDurationPicker) {
        this.openDurationList(event);
      } else {
        this.showDurationPicker = false;
      }
    },
    /**
     * Open the duration list
     */
    openDurationList(e) {
      e.preventDefault();
      this.durationPickerMax =
        this.resource.max_booking_duration_sec || 24 * 60 * 60;
      this.durationPickerX =
        e.srcElement.getBoundingClientRect().left +
        e.srcElement.getBoundingClientRect().width / 2;
      this.durationPickerY =
        e.srcElement.getBoundingClientRect().top +
        e.srcElement.getBoundingClientRect().height;
      this.showDurationPicker = true;
    },
    /**
     * Select a custom duration, it may select a predefined duration too
     */
    selectCustom(item) {
      this.showDurationPicker = false;
      if (
        this.durationOptions.filter(
          (duration) => duration.duration == item.value
        ).length > 0
      ) {
        this.customDuration.time = 0;
        this.customDuration.duration = 0;
      } else {
        this.customDuration.time = item.text;
        this.customDuration.duration = item.value;
      }
      this.setFilterInitialDuration(item.value);
    },
    /**
     * Select time and proceed to the booking phase
     */
    selectTime(timeblock, end_timestamp, duration) {
      if (!duration) {
        duration = this.filtersUsed.initialDuration;
      }
      this.selectResource({
        resource: this.resource,
        location: this.location,
        start_timestamp: timeblock.start_timestamp,
        duration,
        end_timestamp: end_timestamp || timeblock.start_timestamp + duration,
      });
      this.$router.push({ name: "auth" });
    },
    openPicker() {
      this.$router.push({
        name: "resource-picker",
        params: { resource_id: this.$route.params.resource_id },
      });
    },
    copyShareLink() {
      this.$copyText(window.location.href.replace(/mode=[a-z]+/, ""));
      this.appendSnackbarError({
        type: "success",
        message: "Link copied to clipboard",
      });
    },
    handleScroll() {
      if (this.$refs.durationPicker) {
        this.durationPicker.showStart = this.$refs.durationPicker.scrollTop > 0;
        this.durationPicker.showEnd =
          this.$refs.durationPicker.scrollTop <
          this.$refs.durationPicker.scrollHeight -
            this.$refs.durationPicker.clientHeight;
      }
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
      if (this.showDatePicker) {
        this.$nextTick(() => {
          this.$refs.calendarDatePicker.$refs.datePicker.activePicker = "MONTH";
        });
      }
    },
  },
};
</script>

<style scoped>
.carousel-container {
  border-radius: 4px;
}
.location img {
  width: 100%;
  border-radius: 4px;
}
.room-details h1 {
  margin-top: 28px;
  font-weight: 500;
  margin-bottom: 0;
}
.room-details h3 {
  margin: 0;
  font-weight: 400;
}
.room-details h5 {
  font-weight: 500;
}
.room-details .price {
  display: flex;
  flex-direction: row;
}
.room-details .price > * {
  margin-right: 24px;
  font-weight: 500;
  &.price-name {
    color: rgba(var(--optix-theme-defaultFontRGBColor), 0.54);
  }
}
.room-details .description {
  line-height: 28px;
}
.room-details .prices,
.room-details .description,
.room-details .amenities,
.room-details .location,
.room-details > * > .tour-link,
.room-details .cancellation-policy {
  border-top: 1px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.24);
  margin-top: 24px;
  padding-top: 24px;
}
.room-details > * > .tour-link {
  padding-top: 32px;
  margin-bottom: -16px;
}
.room-details p {
  margin: 16px 0;
  font-weight: 400;
}
.room-details .share-button {
  width: 100px;
  text-align: right;
  float: right;
  font-weight: 500;
  margin-top: -8px;
}
.room-details .share-button button:hover {
  background: rgba(var(--optix-theme-accentRGBColor), 0.09);
}
.not-bold {
  font-weight: normal !important;
}
.timeslot {
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  padding-left: 16px;
  height: 60px !important;
}
.timeslot .name {
  font-weight: 400;
  text-transform: none !important;
  padding-top: 4px;
  margin-bottom: -4px;
  font-size: 14px;
  color: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.54);
}
.time-picker .base-button {
  margin-bottom: 8px;
  height: 48px;
}
h2 {
  margin: 36px 0 16px;
}
.outlined {
  border-radius: 6px;
  background: transparent;
  color: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.87);
  border: 2px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.24);
  overflow: hidden;
}
.outlined div:hover {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.03);
}

.amenities .list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-content: stretch;
  align-items: stretch;
}
.amenities .list .amenity {
  display: flex;
  flex-direction: row;
  width: 33%;
  margin-top: 16px;
}
.month-picker {
  font-size: 20px;
  font-weight: 500;
}
.duration-picker > div {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: stretch;
}
.duration-picker .option {
  width: 25%;
  flex-grow: 1;
  flex-basis: 25%;
  display: flex;
  text-align: center;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.duration-picker .option.selected {
  color: rgba(var(--optix-theme-accentTextRGBColor), 0.87);
  background: var(--optix-theme-accentColor);
}
.duration-picker .option.selected .v-icon {
  color: rgba(var(--optix-theme-accentTextRGBColor), 0.87) !important;
}

.day-picker {
  margin-top: 8px;
  text-transform: uppercase;
}

.loading {
  width: 100%;
}
.loading > * {
  width: 50%;
}
.view-resource {
  position: fixed;
  border: 1px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.26);
  border-right: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--optix-theme-backgroundColor);
  padding: 3px 8px;
  right: 0;
}
.book-now .button-wrapper {
  border-top: 1px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.26);
  background: var(--optix-theme-backgroundColor);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 8px;
  padding-bottom: 48px;
}
.book-now .button-wrapper button {
  height: 48px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
.book-now {
  height: 101px;
}
.time-options .base-button {
  font-size: 16px !important;
}
@media (min-width: 800px) {
  .time-options::-webkit-scrollbar {
    display: none;
    padding-bottom: 30px;
  }
  .time-options {
    position: relative;
    overflow-y: auto;
  }

  .fade {
    position: relative;
    height: 30px;
    background-image: linear-gradient(
      rgba(var(--optix-theme-backgroundRGBColor), 0),
      rgba(var(--optix-theme-backgroundRGBColor), 0.5),
      rgba(var(--optix-theme-backgroundRGBColor), 1)
    );
    width: 100%;
    margin-top: -30px;
  }
  .fade.top {
    background-image: linear-gradient(
      rgba(var(--optix-theme-backgroundRGBColor), 1),
      rgba(var(--optix-theme-backgroundRGBColor), 0.5),
      rgba(var(--optix-theme-backgroundRGBColor), 0)
    );
    margin-top: 0;
    margin-bottom: -30px;
    z-index: 30;
  }
}
.no-times {
  width: 100%;
  padding: 50px 0;
  text-align: center;
}
.no-times .o-icon {
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.1);
  font-size: 100px;
  margin-bottom: 24px;
}
</style>
