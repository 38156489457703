<template>
  <layout-content>
    <o-layout row>
      <o-flex class="left-side">
        <o-text-field label="Email" v-model="user.email" disabled />
        <div class="two-side-by-side">
          <o-text-field
            label="First name*"
            v-model="form.name"
            ref="name"
            :error-messages="validateErrors.name"
            @keydown.enter.prevent="$refs.lastName.focus()"
          />
          <o-text-field
            class="ml-3"
            label="Last name*"
            v-model="form.surname"
            ref="lastName"
            :error-messages="validateErrors.surname"
            @keydown.enter.prevent="
              showPhone ? $refs.phone.focus() : $refs.password.focus()
            "
          />
        </div>
        <o-text-field
          v-if="showPhone"
          type="text"
          :label="'Phone number' + (requirePhone ? '*' : '')"
          v-model="form.phone"
          ref="phone"
          :error-messages="validateErrors.phone"
          @keydown.enter.prevent="$refs.password.focus()"
        />
        <o-text-field
          type="password"
          label="Choose a password*"
          v-model="form.password"
          ref="password"
          :error-messages="validateErrors.password"
          @keydown.enter.prevent="next"
        />
        <base-button
          class="mt-5 next-button"
          @click="next()"
          :processing="processing"
          >Continue</base-button
        >
      </o-flex>
      <o-flex v-if="$vuetify.breakpoint.mdAndUp" class="right-side">
        <booking-side-card :booking="selectedBooking" />
      </o-flex>
    </o-layout>
  </layout-content>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import OptixV1Service from "../services/OptixV1Service";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";
import ValidatePassword from "@/mixins/ValidatePassword";

export default {
  mixins: [Validate, ValidatePassword],
  data() {
    return {
      processing: false,
      form: {
        password: "",
        name: "",
        surname: "",
        phone: "",
      },
      showPhone: false,
      requirePhone: false,
    };
  },
  computed: {
    ...mapState(["user", "lookup"]),
    ...mapGetters(["selectedBooking"]),
  },
  mounted() {
    if (!this.selectedBooking.resource.resource_id) {
      this.$router.push({ name: "find" });
      return;
    }
    if (!this.lookup.member_account) {
      this.$router.replace({ name: "auth" });
      return;
    }
    this.$refs.name.focus();

    OptixV1Service.profileFields().then((response) => {
      const phoneProperty = response.data.response.fields.find(
        (f) => f.field === "phone"
      );
      if (phoneProperty) {
        this.showPhone =
          phoneProperty.is_editable && phoneProperty.display_onboarding;
        this.requirePhone = phoneProperty.is_required;
      }
    });
  },
  methods: {
    ...mapMutations(["setUser"]),
    next() {
      Validate.utils.trimFields(this.form);
      this.form.phone = this.form.phone.replace(/[^0-9+]+/g, "");

      const rules = {
        name: [Validate.rules.required],
        surname: [Validate.rules.required],
        password: [Validate.rules.password],
      };
      if (this.showPhone) {
        rules.phone = this.requirePhone
          ? [Validate.rules.required, Validate.rules.phone]
          : [Validate.rules.optional([Validate.rules.phone])];
      }
      const extra_promises = [
        this.checkPasswordStrength("password", this.form.password),
      ];

      this.validateForm(this.form, rules, extra_promises)
        .then(() => {
          this.processing = true;
          OptixV1Service.complete({ ...this.form, email: this.user.email })
            .then((success) => {
              if (success.data.response && success.data.response.access_token) {
                this.$optix.env.access_token =
                  success.data.response.access_token;
                this.setUser(success.data.response.user);
                this.$router.push({ name: "payment" });
              } else {
                if (
                  success.data.response.error === "User status is not PENDING"
                ) {
                  this.$router.push({ name: "auth" });
                } else {
                  this.addFormFieldError(
                    "password",
                    success.data.response.error
                  );
                }
              }
              this.processing = false;
            })
            .catch(() => {
              this.addFormFieldError(
                "password",
                "An error happened saving your password"
              );
              this.processing = false;
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.left-side p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
}
</style>
