<template>
  <layout-content>
    <o-layout column @click.stop="closeDurationPicker()">
      <o-flex class="header">
        <h1 v-if="showTitle">{{ title }}</h1>
        <!-- Filters -->
        <div class="filters-wrap">
          <div class="filters" @scroll="handleScroll()">
            <template v-if="loading">
              <skeleton-loader
                class="o-filter-placeholder"
                width="115"
                height="32"
                radius="4"
              />
              <skeleton-loader
                class="o-filter-placeholder"
                width="115"
                height="32"
                radius="4"
              />
              <skeleton-loader
                class="o-filter-placeholder"
                width="115"
                height="32"
                radius="4"
              />
              <skeleton-loader
                class="o-filter-placeholder"
                width="115"
                height="32"
                radius="4"
              />
              <skeleton-loader
                class="o-filter-placeholder"
                width="115"
                height="32"
                radius="4"
              />
            </template>
            <template v-else>
              <o-filter-list-multiple
                v-if="availableLocations.length > 1"
                label="Location"
                icon="o-icon-building"
                v-model="filterLocations"
                :items="availableLocations"
                item-text="name"
                item-value="location_id"
              />
              <o-filter-required-date
                icon="o-icon-calendar"
                :clearable="false"
                v-model="filterInitialDate"
                :min="today"
              />
              <o-filter-time
                icon="o-icon-clock"
                v-model="filterInitialTime"
                label="Starting"
                :allowedMinutes="[0, 15, 30, 45]"
                selectedPrefix
              />
              <o-filter-list
                label="Duration"
                icon="o-icon-clock"
                v-model="filterInitialDuration"
                :items="durations"
              />
              <o-filter-list
                label="Capacity"
                icon="o-icon-team"
                v-model="filterMinimumCapacity"
                :items="capacities"
              />
              <o-filter-list
                v-if="resourceTypes.length > 1"
                label="Type"
                icon="o-icon-resource"
                v-model="filterResourceType"
                :items="resourceTypes"
              />
            </template>
          </div>
          <div
            class="overflow overflow-left"
            :class="{ 'overflow-hidden': !overflowIndicators.left }"
          ></div>
          <div
            class="overflow overflow-right"
            :class="{ 'overflow-hidden': !overflowIndicators.right }"
          ></div>
        </div>
      </o-flex>
      <tour-link v-if="showTourLink" />
      <o-flex>
        <transition name="slide-fade" mode="out-in">
          <div v-if="loading">
            <div v-for="n in 3" :key="'skeleton' + n">
              <div class="resource-card">
                <div class="image">
                  <v-img :aspect-ratio="240 / 170" :width="imageWidth">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <skeleton-loader radius="4" />
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <div class="body">
                  <h2>
                    <skeleton-loader width="30%" :height="20" radius="10" />
                  </h2>
                  <h3>
                    <skeleton-loader
                      style="margin-top: 6px"
                      width="50%"
                      :height="20"
                      radius="10"
                    />
                  </h3>
                  <div
                    class="timeblocks"
                    style="margin-top: 20px; margin-bottom: 10px"
                  >
                    <skeleton-loader :height="40" radius="4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!loading" key="content">
            <div v-if="this.resources.length == 0" class="no-rooms">
              <o-icon icon="o-icon-calendar" />
              <div>Sorry, no resources available at this time.</div>
            </div>
            <div v-else>
              <div
                v-if="sortedResources.length == 0"
                key="noMatch"
                class="no-rooms"
              >
                <o-icon icon="o-icon-calendar" />
                <div>
                  Sorry, no resources match the current filter. Try changing
                  filters.
                </div>
              </div>
              <div
                v-for="(resource, index) in sortedResources"
                :key="resource.resource_id"
                class="resource-card clickable"
                :class="{ first: index === 0 }"
                @click="gotoRoom(resource)"
                @mouseenter="handleHoverIn(resource)"
                @mouseleave="handleHoverOut()"
              >
                <div class="image">
                  <v-carousel
                    hide-delimiters
                    :show-arrows="
                      resource.images.length > 1 && isHovered(resource)
                    "
                    height="auto"
                  >
                    <v-carousel-item
                      v-for="image in resource.images"
                      :key="image.url"
                    >
                      <v-img
                        :src="$optix.utils.resizedImage(image.url, 500)"
                        :srcset="
                          $optix.utils.resizedImage(
                            image.url,
                            500,
                            false,
                            false,
                            'srcset'
                          )
                        "
                        :aspect-ratio="240 / 170"
                        :width="imageWidth"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <skeleton-loader radius="4" />
                          </v-row>
                        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                  <div class="prices">
                    <div v-if="resource.hourly_price_range">
                      <strong>{{ resource.hourly_price_range }}</strong
                      >{{
                        resource.hourly_price_range !== "Free" ? "/hr" : ""
                      }}
                    </div>
                    <div v-if="resource.daily_price_range">
                      <strong>{{ resource.daily_price_range }}</strong
                      >/dy
                    </div>
                  </div>
                </div>
                <div class="body">
                  <h2>{{ resource.title }}</h2>
                  <h3>
                    {{ resource.type.name }}
                    <svg class="font-themed-svg" width="4" height="7">
                      <circle
                        cx="2"
                        cy="2"
                        r="2"
                        :fill="$optix.theme.darkMode ? '#fff' : '#000'"
                      />
                    </svg>
                    {{ resource.capacity }}
                    {{ resource.capacity > 1 ? "people" : "person" }}
                  </h3>
                  <h4>
                    <o-icon
                      class="accent-colored"
                      icon="o-icon-location-pin"
                    />&nbsp;
                    <address-description
                      :location="indexedLocations[resource.location_id]"
                    />
                  </h4>
                  <div class="timeblocks">
                    <div
                      v-for="(brick, brickIndex) in getBricks(resource)"
                      class="timebrick"
                      :key="brickIndex"
                    >
                      <base-button
                        v-if="brick.type == 'FREE_SELECT'"
                        @click.stop="openDurationList(brick, resource, $event)"
                        >{{ formatTime(brick) }}</base-button
                      >
                      <base-button
                        v-else-if="brick.type == 'TIME_SLOTS'"
                        class="timeslot"
                        @click.stop="
                          proceedWithBookingViaTimeslot(
                            resource,
                            brick.start_timestamp,
                            brick.start_timestamp + brick.max_length
                          )
                        "
                        >{{ formatTime(brick) }}</base-button
                      >
                      <base-button
                        :outlined="true"
                        v-else-if="brick.type == 'MORE'"
                        class="more"
                        @click.stop="gotoRoom(resource)"
                        >MORE</base-button
                      >
                      <base-button
                        :outlined="true"
                        v-else-if="brick.type == 'DAY'"
                        @click.stop="gotoRoom(resource, brick.day)"
                        >{{ brick.day.toFormat(dateFormat) }}</base-button
                      >
                      <base-button :placeholder="true" v-else></base-button>
                    </div>
                  </div>
                  <!--h4>{{index}}) {{resource.resource_id}} - {{resource.title}} - Location {{indexedLocations[resource.location_id].name}} - {{indexedLocations[resource.location_id].timezone}}</h4>
                  Cap: {{resource.capacity}} Score {{resource.score}}
                  <br/>
                  <div v-for="(block, timestamp) in resource.timeblocks" :key="timestamp" class="book-button">
                    {{generateButtonLabel(timestamp, indexedLocations[resource.location_id].timezone)}}
                  </div>
                  {{resource.score_assists}}-->
                </div>
              </div>
            </div>
          </div>
        </transition>
      </o-flex>
    </o-layout>
    <v-menu
      v-model="showDurationPicker"
      :position-x="durationPickerX"
      :position-y="durationPickerY"
      absolute
      offset-y
      :close-on-click="false"
    >
      <v-list max-height="300">
        <v-list-item
          v-for="(item, index) in durationPickerList"
          :key="index"
          @click="proceedWithBooking(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </layout-content>
</template>

<script>
import _sortBy from "lodash/sortBy";
import OptixV2Service from "@/services/OptixV2Service";
import PricesMixin from "@/mixins/PricesMixin";
import { DateTime } from "luxon";
import { mapSetter } from "@/vuex/utils";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  mixins: [PricesMixin],
  data() {
    return {
      loading: true,
      durations: [{ value: null, text: "Any duration" }],
      capacities: [{ value: null, text: "Any capacity" }],
      resources: [],
      resourceTypes: [],
      lastQuery: null,
      scoreStats: {},
      showDurationPicker: false,
      durationPickerX: 0,
      durationPickerY: 0,
      durationPickerMax: 99999999,
      durationPickerMin: 0,
      resourceSelected: {},
      brickSelected: {},
      overflowIndicators: {
        left: 0,
        right: 0,
      },
      today: null,
      hoveredResource: null,
    };
  },
  watch: {
    filtersUsed(newFilters) {
      let lastQuery = JSON.stringify({
        initialDate: newFilters.initialDate,
        // initialTime: newFilters.initialTime,
        locations: newFilters.locations,
      });
      if (lastQuery == this.lastQuery) {
        this.scoreResources();
        return;
      }
      this.updateResources();
      this.lastQuery = lastQuery;
    },
  },
  computed: {
    ...mapState(["organization"]),
    ...mapGetters(["filtersUsed"]),
    ...mapSetter(
      mapState([
        "mode",
        "availableLocations",
        "filterInitialDate",
        "filterLocations",
        "filterInitialTime",
        "filterInitialDuration",
        "filterMinimumCapacity",
        "filterResourceType",
      ]),
      {
        filterInitialDate(payload) {
          this.setFilterInitialDate(payload);
        },
        filterLocations(payload) {
          this.setFilterLocations(payload);
        },
        filterInitialTime(payload) {
          this.setFilterInitialTime(payload);
        },
        filterInitialDuration(payload) {
          this.setFilterInitialDuration(payload);
        },
        filterMinimumCapacity(payload) {
          this.setFilterMinimumCapacity(payload);
        },
        filterResourceType(payload) {
          this.setFilterResourceType(payload);
        },
      }
    ),
    title() {
      return this.organization.drop_in.title
        ? this.organization.drop_in.title
        : "Find a space";
    },
    showTitle() {
      return this.mode == "fullscreen" || this.mode == "modal";
    },
    showTourLink() {
      return this.organization && this.organization.tours;
    },
    imageWidth() {
      if (this.$vuetify.breakpoint.width <= 550) {
        return "100%";
      } else if (this.$vuetify.breakpoint.width >= 1000) {
        return "300px";
      }
      return "240px";
    },
    dateFormat() {
      if (this.$vuetify.breakpoint.width < 800) {
        return "LLL d";
      }
      return "EEE, LLL d";
    },
    indexedLocations() {
      let indexed = {};
      this.availableLocations.map((location) => {
        indexed[location.location_id] = location;
      });
      return indexed;
    },
    allowedTypes() {
      return (
        (this.$optix.env.get.types || "")
          .split(",")
          .filter((type) => type.length > 0) || []
      );
    },
    sortedResources() {
      this.getResourceTypes();
      return _sortBy(this.resources, (resource) => -resource.score)
        .filter((resource) => {
          if (resource?.type?.name) {
            const isInAllowedTypes =
              this.allowedTypes.length > 0 &&
              this.allowedTypes.indexOf(resource.type.name) === -1;
            const isInFilter =
              this.filterResourceType !== null &&
              this.filterResourceType !== resource.type.name;
            if (isInAllowedTypes || isInFilter) return false;
          }
          return resource.score >= 0;
        })
        .map((resource) => {
          resource.hourly_price_range = this.getPriceRange(resource, false);
          resource.daily_price_range = this.getPriceRange(resource, true);
          return resource;
        });
    },
    durationPickerList() {
      return this.durations.filter(
        (duration) =>
          duration.value <= this.durationPickerMax &&
          duration.value >= this.durationPickerMin &&
          duration.value
      );
    },
  },
  mounted() {
    this.$optix.env.access_token = null;
    if (this.$optix.env.get.duration) {
      this.setFilterInitialDuration(this.$optix.env.get.duration);
    } else {
      this.setFilterInitialDuration(null);
    }
    if (this.$optix.env.get.date) {
      this.setFilterInitialDate(this.$optix.env.get.date);
    }
    if (this.$optix.env.get.time) {
      this.setFilterInitialTime(this.$optix.env.get.time);
    }
    if (this.$optix.env.get.capacity) {
      this.setFilterMinimumCapacity(this.$optix.env.get.capacity);
    }
    //this.setFilterInitialDate(null);
    this.today = DateTime.local().toISO();
    for (let i = 2; i < 21; i++) {
      this.capacities.push({ value: i, text: i + "+" });
    }

    for (let len = 15 * 60; len <= 23.5 * 60 * 60; len += 15 * 60) {
      let label = len / 60 + (this.intermediateSize ? "m" : " mins");
      if (len > 90 * 60) {
        let hour = Math.floor(len / 60 / 60);
        label = hour + "h " + (len - hour * 60 * 60) / 60 + "m";
      }
      this.durations.push({
        value: len,
        text: label,
      });
    }
    this.updateResources();
    this.overflowIndicators.right = this.$vuetify.breakpoint.width <= 550;
  },
  methods: {
    handleScroll(e) {
      this.overflowIndicators.left = e.srcElement.scrollLeft > 0;
      this.overflowIndicators.right =
        e.srcElement.scrollLeft <
        e.srcElement.scrollWidth - e.srcElement.clientWidth;
    },
    gotoRoom(resource, day) {
      if (day) {
        this.setFilterInitialDate(day.toISO().substr(0, 10));
      }
      this.$router.push({
        name: "resource",
        params: { resource_id: resource.resource_id },
      });
    },
    closeDurationPicker() {
      this.showDurationPicker = false;
    },
    openDurationList(brick, resource, e) {
      this.resourceSelected = resource;
      this.brickSelected = brick;
      e.preventDefault();
      // If filter is selected, then proceed without selecting the duration
      if (this.filtersUsed.initialDuration) {
        this.proceedWithBooking(this.filtersUsed.initialDuration);
        return;
      }
      this.durationPickerMax = brick.max_length;
      if (
        resource.max_booking_duration_sec > 0 &&
        this.durationPickerMax > resource.max_booking_duration_sec
      ) {
        this.durationPickerMax = resource.max_booking_duration_sec;
      }
      this.durationPickerMin = resource.min_booking_duration_sec || 0;
      this.durationPickerX = e.srcElement.getBoundingClientRect().left;
      this.durationPickerY =
        e.srcElement.getBoundingClientRect().top +
        e.srcElement.getBoundingClientRect().height;
      if (this.showDurationPicker == false) {
        this.showDurationPicker = true;
      }
    },
    proceedWithBooking(duration) {
      // Select a duration and go to auth
      this.selectResource({
        resource: this.resourceSelected,
        location: this.indexedLocations[this.resourceSelected.location_id],
        start_timestamp: this.brickSelected.start_timestamp,
        duration: duration,
        end_timestamp: this.brickSelected.start_timestamp + duration,
      });
      this.$router.push({ name: "auth" });
    },
    proceedWithBookingViaTimeslot(resource, start, end) {
      this.selectResource({
        resource: resource,
        location: this.indexedLocations[resource.location_id],
        start_timestamp: start,
        duration: end - start,
        end_timestamp: end,
      });
      this.$router.push({ name: "auth" });
    },
    formatTime(brick) {
      if (brick.type == "TIME_SLOTS") {
        let start = DateTime.fromSeconds(brick.start_timestamp, {
          zone: brick.timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE);
        let end = DateTime.fromSeconds(
          brick.start_timestamp + brick.max_length,
          {
            zone: brick.timezone,
          }
        ).toLocaleString(DateTime.TIME_SIMPLE);
        return `${start} - ${end}`;
      }
      if (brick.start_timestamp) {
        return DateTime.fromSeconds(brick.start_timestamp, {
          zone: brick.timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE);
      }
      return "";
    },
    getBricks(resource) {
      let width = this.$vuetify.breakpoint.width;
      let brickMax = 5;
      if (width <= 980 && width > 900) {
        brickMax = 4;
      } else if (width <= 900 && width > 300) {
        brickMax = 3;
      }
      if (resource.type.time_selection_type == "TIME_SLOTS") {
        if (width > 915) {
          brickMax = 4;
        } else if (width <= 915 && width > 720) {
          brickMax = 3;
        } else {
          brickMax = 2;
        }
      }
      let bricks = [];
      for (let bn = 0; bn < brickMax; bn++) {
        bricks.push({});
      }
      if (resource.timeblocks.length == 0) {
        return bricks;
      }
      let timezone = this.indexedLocations[resource.location_id].timezone;

      let brickNum = 0;
      let time_count = 0;
      for (brickNum = 0; brickNum < brickMax; brickNum++) {
        if (resource.timeblocks[brickNum]) {
          let start_time = DateTime.fromSeconds(
            resource.timeblocks[brickNum].start_timestamp,
            {
              zone: timezone,
            }
          );
          if (
            start_time.toFormat("yyyy-LL-dd") != this.filtersUsed.initialDate
          ) {
            break;
          }
          bricks[brickNum].type = resource.type.time_selection_type;
          bricks[brickNum].start_timestamp =
            resource.timeblocks[brickNum].start_timestamp;
          bricks[brickNum].max_length =
            resource.timeblocks[brickNum].max_length;
          bricks[brickNum].timezone = timezone;
          time_count++;
        } else {
          break;
        }
      }
      Object.keys(resource.days_ahead).map((days) => {
        if (brickNum < brickMax) {
          let nextDay = DateTime.fromISO(this.filtersUsed.initialDate).plus({
            days: days,
          });
          bricks[brickNum].type = "DAY";
          bricks[brickNum].day = nextDay;
          brickNum++;
        }
      });
      if (time_count > brickMax - 1) {
        bricks[brickMax - 1].type = "MORE";
      }
      return bricks;
    },
    generateButtonLabel(timestamp, timezone) {
      return DateTime.fromSeconds(parseInt(timestamp), {
        zone: timezone,
      }).toLocaleString(DateTime.DATETIME_FULL);
    },
    updateResources() {
      if (!this.filtersUsed.initialDate || !this.filtersUsed.locations) {
        return;
      }
      // Load all resources
      this.loading = true;
      // TODO do something that avoids reloading from server
      OptixV2Service.loadResourcesBasedOn(
        this.filtersUsed,
        this.availableLocations.map((location) => location.location_id)
      )
        .then(async (resources) => {
          this.resources = resources;
          await this.scoreResources();
        })
        .catch(() => {
          this.appendSnackbarError({
            message:
              "Sorry, we had trouble fetching some details. Please try again later.",
          });
        });
    },
    getResourceTypes() {
      this.resourceTypes = this.resources.reduce((acc, curr) => {
        const typeName = curr?.type?.name;
        if (
          !acc.find((v) => v?.value === typeName) &&
          (this.allowedTypes.length === 0 ||
            this.allowedTypes.find((v) => v === typeName))
        ) {
          acc.push({ text: typeName, value: typeName });
        }
        return acc;
      }, []);
    },
    async scoreResources() {
      this.loading = true;
      this.scoreStats = await OptixV2Service.scoreResources(
        this.resources,
        this.filtersUsed
      );
      this.loading = false;
    },
    handleHoverIn(resource) {
      this.hoveredResource = resource;
    },
    handleHoverOut() {
      this.hoveredResource = null;
    },
    isHovered(resource) {
      return this.hoveredResource === resource;
    },
    ...mapMutations([
      "selectResource",
      "setFilterInitialDate",
      "setFilterLocations",
      "setFilterInitialTime",
      "setFilterInitialDuration",
      "setFilterMinimumCapacity",
      "setFilterResourceType",
      "appendSnackbarError",
    ]),
  },
};
</script>
<style scoped>
.header h1 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 32px;
}

@media (max-width: 799px) {
  .header h1 {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.filters-wrap {
  position: relative;
}
.filters {
  display: flex;
  flex-direction: row;
  overflow: show;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-bottom: 16px;
  font-size: 14px;
}
.filters-wrap .overflow {
  height: 100%;
  width: 50px;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.filters-wrap .overflow-hidden {
  opacity: 0;
}
.filters-wrap .overflow-left {
  background-image: linear-gradient(
    to right,
    rgba(var(--optix-theme-backgroundRGBColor), 1),
    rgba(var(--optix-theme-backgroundRGBColor), 0.3),
    rgba(var(--optix-theme-backgroundRGBColor), 0)
  );
  top: 0px;
  left: 0px;
}
.filters-wrap .overflow-right {
  background-image: linear-gradient(
    to right,
    rgba(var(--optix-theme-backgroundRGBColor), 0),
    rgba(var(--optix-theme-backgroundRGBColor), 0.3),
    rgba(var(--optix-theme-backgroundRGBColor), 1)
  );
  top: 0px;
  right: 0px;
}
.filters::-webkit-scrollbar {
  display: none;
}
@media (min-width: 550px) {
  .filters {
    flex-wrap: wrap;
    overflow: auto;
  }
  .filters .overflow-right {
    display: none;
  }
}
.filters >>> .o-filter {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.resource-card {
  position: relative;
  margin: 0px 0px 24px 0px;
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.resource-card::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0;
  transition: 0.3s;
  content: "";
  z-index: -1;
}

.resource-card:hover::before {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  left: -10px;
  top: -10px;
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.06);
  opacity: 1;
}

.resource-card .image {
  position: relative;
}
.resource-card h2 {
  font-weight: 500;
}
@media (min-width: 551px) {
  .resource-card .image {
    height: 170px;
  }
}
@media (min-width: 1000px) {
  .resource-card .image {
    height: 212.5px;
  }
}

.resource-card .image > div {
  border-radius: 4px;
}

.resource-card .image > div.prices {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.resource-card .image div.prices > div {
  display: inline-block;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: #fff;
  padding: 0px 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.49px;
  margin-right: 8px;
}

.resource-card .body {
  justify-content: center;
  width: 100%;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}

.resource-card .body > * {
  margin-bottom: 3px;
  margin-top: 0px;
  flex-shrink: 1;
}

.resource-card .body > h3 {
  margin-bottom: 0px;
  font-weight: 400;
}
.resource-card .body > h4 {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  margin-top: -2px;
}
.resource-card .body > h4 .o-icon {
  font-size: 16px;
  margin-right: 4px;
}

.resource-card .body .timeblocks {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.resource-card .body .timeblocks .timebrick {
  padding-right: 12px;
  flex-grow: 1;
  flex-basis: 50px;
}

.resource-card .body .timeblocks .timebrick .empty {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.06);
  height: 35px;
  width: 100%;
}
.resource-card .body .timeblocks {
  margin-right: -12px;
}
.resource-card .body .timeblocks .timebrick > * {
  width: 100%;
}

.resource-card.faded {
  opacity: 0.3;
}

@media (max-width: 550px) {
  .resource-card {
    flex-direction: column;
  }
  .resource-card .image {
    width: 100%;
  }
  .resource-card .body {
    margin: 16px 0px 16px 0px;
    padding: 0px;
  }
}

.book-button {
  display: inline-block;
  background: #aaa;
  margin: 2px;
  padding: 1px 3px;
  font-size: 13px;
}
.no-rooms {
  width: 100%;
  padding: 50px 0px;
  text-align: center;
}
.no-rooms .o-icon {
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.1);
  font-size: 100px;
  margin-bottom: 24px;
}
.o-filter-placeholder {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
