<template>
  <layout-content>
    <o-layout row>
      <o-flex class="left-side">
        <p>Use a social account</p>
        <div class="social-login">
          <!-- <social-login-button
            :organization="organization"
            social-network="facebook"
          /> -->
          <social-login-button
            :organization="organization"
            social-network="google"
          />
          <social-login-button
            :organization="organization"
            social-network="linkedin"
          />
        </div>
        <p>or use your email</p>
        <o-text-field
          label="Email"
          v-model="form.email"
          hint="If you've booked here before, use the same email"
          persistent-hint
          :error-messages="validateErrors.email"
          ref="email"
          @keydown.enter.prevent="next"
        />
        <recaptcha
          ref="recaptcha"
          v-model="form.recaptcha_token"
          @verify="next"
        />
        <base-button
          class="mt-5 next-button"
          @click="next()"
          :processing="processing"
          >Next</base-button
        >
        <p class="terms">
          By continuing, you accept the
          <a href="/terms" target="_blank">Terms &amp; Conditions</a> and
          <a href="/privacy" target="_blank">Privacy Policy</a
          ><template v-if="hasTerms"
            >, as well as
            <a @click="openTerms = true"
              >{{ organization.name }} Terms</a
            ></template
          >.
        </p>
      </o-flex>
      <o-flex v-if="$vuetify.breakpoint.mdAndUp" class="right-side">
        <booking-side-card :booking="selectedBooking" />
      </o-flex>
    </o-layout>
    <text-modal
      v-model="openTerms"
      :pre="true"
      :text="organization.custom_terms"
      title="Terms of Service"
    />
    <text-modal
      v-model="openCancellationPolicy"
      :text="organization.drop_in.cancellation_policy"
      title="Cancellation Policy"
    />
  </layout-content>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import _get from "lodash/get";
import OptixV1Service from "../services/OptixV1Service";
import Recaptcha from "@/components/Recaptcha";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";

export default {
  components: { Recaptcha },
  mixins: [Validate],
  data() {
    return {
      processing: false,
      openTerms: false,
      openCancellationPolicy: false,
      form: {
        email: "",
        recaptcha_token: "",
      },
    };
  },
  computed: {
    ...mapState(["organization"]),
    ...mapGetters(["selectedBooking", "paymentGateway"]),
    hasTerms() {
      return !!this.organization.custom_terms;
    },
  },
  mounted() {
    if (!this.selectedBooking.resource.resource_id) {
      this.$router.push({ name: "find" });
      return;
    }
    this.$gtag.event("begin_checkout", {
      items: [
        {
          id: this.selectedBooking.resource.resource_id,
          name: this.selectedBooking.resource.name,
          quantity:
            (this.selectedBooking.period.end.toSeconds() -
              this.selectedBooking.period.start.toSeconds()) /
            60 /
            60,
          price: this.selectedBooking.subtotal,
        },
      ],
    });
    window.addEventListener("message", this.handleSocial);
    OptixV1Service.getVenue().then((venue) => {
      let gateway = venue.data.response.payment_gateway;
      gateway.payment_engine_version =
        venue.data.response.membership.payment_engine_version;
      this.setPaymentGateway(gateway);
      if (this.paymentGateway) {
        if (this.paymentGateway.processor == "stripe") {
          if (window.stripeLoaded) {
            return false;
          }
          let script_url = null;
          if (this.paymentGateway.engine == 2) {
            script_url = "https://js.stripe.com/v3/";
          } else {
            script_url = "https://js.stripe.com/v2/";
          }
          var script = document.createElement("script");
          script.setAttribute("type", "text/javascript");
          script.setAttribute("src", script_url);
          script.setAttribute("id", "stripeJs");
          document.getElementsByTagName("head")[0].appendChild(script);
          window.stripeLoaded = true;
        }
      }
    });
    this.$refs.email.focus();
  },
  methods: {
    ...mapMutations([
      "setUser",
      "setLookupContext",
      "setPaymentGateway",
      "appendSnackbarError",
    ]),
    next() {
      this.validateForm(this.form, {
        email: [Validate.rules.email],
      })
        .then(() => {
          if (!this.form.recaptcha_token) {
            this.$refs.recaptcha.execute();
            return;
          }

          this.processing = true;
          OptixV1Service.lookup(this.form)
            .then((e) => {
              /* Returns
                e.data.response { 
                  user_account: "21972"
                  user_status: "1"
                  user_terms_ok: true
                  member_account: "7245"
                  member_status: "1"
                }
              */
              this.setUser({ email: this.form.email });
              this.setLookupContext(e.data.response);
              if (e.data.response.user_status == "1") {
                // Active user, ready to login
                this.$router.replace({ name: "password" });
              } else if (e.data.response.user_status == "2") {
                // New user, must set a password
                this.$router.replace({ name: "complete" });
              } else {
                // Inactive or disabled user
                this.addFormFieldError("email", "Oops, you cannot log in");
              }
              this.processing = false;
            })
            .catch((e) => {
              this.appendSnackbarError({
                message: _get(
                  e,
                  "response.data.response.message",
                  "An error occurred, please try again later."
                ),
              });
              this.processing = false;
              this.$refs.recaptcha.reset();
            });
        })
        .catch(() => {});
    },
    handleSocial(event) {
      if (event.data.command && event.data.command == "loginAs") {
        /* Returns
          event.data.info {
            access_token
            user: { <user data> }
            lookup: { <lookup response> }
          }
        */
        this.$optix.env.access_token = event.data.info.access_token;
        this.setUser(event.data.info.user);
        this.setLookupContext(event.data.info.lookup);
        this.$router.push({ name: "payment" });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleSocial);
  },
};
</script>
<style scoped>
.left-side p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
  color: rgba(var(--theme-defaultFontRGBColor), 0.54);
}
.left-side .terms {
  margin-top: 24px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
}
.social-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 24px;
}
.social-login > div:first-child {
  margin-left: 0px;
}
.social-login > div {
  flex-grow: 1;
  margin-left: 8px;
}
</style>
