import { DateTime } from "luxon";

export default {
  // Handy helper for filters
  filtersUsed(state) {
    return {
      initialDate: state.filterInitialDate,
      locations: state.filterLocations,
      initialTime: state.filterInitialTime,
      initialDuration: state.filterInitialDuration,
      minimumCapacity: state.filterMinimumCapacity,
    };
  },
  selectedStartDateTime(state) {
    if (
      !state.bookingStartTimestamp ||
      !state.bookingDuration ||
      !state.bookingEndTimestamp ||
      !state.locationSelected
    ) {
      return { start: null, end: null, duration: null, timezone: null };
    }
    let start = DateTime.fromSeconds(state.bookingStartTimestamp, {
      zone: state.locationSelected.timezone,
    });
    return {
      start: start,
      end: start.plus({ second: state.bookingDuration }),
      duration: state.bookingDuration,
      timezone: state.locationSelected.timezone,
    };
  },
  selectedBooking(state, getters) {
    if (!state.resourceSelected || !state.locationSelected) {
      return {};
    }
    let period = getters.selectedStartDateTime;

    const taxMode = state.organization.features.tax_mode;
    const taxName = state.organization.features.tax_name || "Tax";

    const result = {
      period,
      resource: state.resourceSelected,
      location: state.locationSelected,
      price_description: null,
      quantity: null,
      subtotal: null,
      total: null,
      tax_label: null,
      tax: null,
    };

    // Drafted booking provides the numbers with respect to tax overrides & conditional pricing
    if (
      state.draftedBooking &&
      state.draftedBooking.bookings &&
      state.draftedBooking.bookings[0]
    ) {
      const payment = state.draftedBooking.bookings[0].payment;

      result.price_description = payment.price_description;
      result.quantity = payment.quantity;
      result.subtotal = payment.total - payment.tax;
      result.total = payment.total;
      result.tax_label = {
        NONE: null,
        EXCLUSIVE: taxName,
        INCLUSIVE: "Includes " + (taxName === "Tax" ? "tax" : taxName),
      }[taxMode];
      result.tax = taxMode === "INCLUSIVE" ? payment.included_tax : payment.tax;
    }

    return result;
  },
  paymentGateway(state) {
    if (!state.organization || !state.organization.payment_gateway) {
      return {
        processor: null,
        engine: null,
      };
    }
    let processor = "none";
    if (state.organization.payment_gateway.gateway_id > 0) {
      processor =
        state.organization.payment_gateway.gateway_id >= 2
          ? "spreedly"
          : "stripe";
    }
    return {
      processor,
      engine: state.organization.payment_gateway.payment_engine_version,
    };
  },
  contactUsEmail(state) {
    if (
      state.locationSelected &&
      state.locationSelected.contact &&
      state.locationSelected.contact.email
    ) {
      return state.locationSelected.contact.email;
    }
    if (state.organization.contact.email) {
      return state.organization.contact.email;
    }
    return false;
  },
};
