/**
 * This service store a set of handy actions interacting with Optix webservices V2
 */
import _get from "lodash/get";
import _forEach from "lodash/forEach";
//import _flatMap from "lodash/flatMap";
//import _findIndex from "lodash/findIndex"
import _find from "lodash/find";
import { DateTime } from "luxon";
import { alignSeconds } from "./TimeService";

export default {
  ws: null,
  store: null,

  /**
   * Init the library (this should be called at App create method)
   * @param {*} ws
   * @param {*} store
   */
  init(ws, store) {
    this.ws = ws;
    this.store = store;
  },

  /**
   * Calculate start and end time for availability filtering
   * @param {*} filters
   */
  calculateStartEnd(filters) {
    let response = {
      baseTime: DateTime.fromISO(
        `${filters.initialDate}T${filters.initialTime}:00`,
        { zone: "utc" }
      ),
    };
    response.start_timestamp = Math.floor(
      response.baseTime.plus({ days: -1 }).toSeconds()
    );
    response.end_timestamp = Math.floor(
      response.baseTime.plus({ days: 5 }).toSeconds()
    );
    return response;
  },

  /**
   * Fetch all resources based on a filter
   * @param {*} filters
   */
  async loadResourcesBasedOn(filters, locationsAvailable) {
    let resources = [];
    let rounds = 10; // Max 10 rounds, avoid
    let counters = {};
    let page = 1;
    let locations =
      filters.locations.length > 0 ? filters.locations : locationsAvailable;
    let per_page = 30;
    let { start_timestamp, end_timestamp } = this.calculateStartEnd(filters);
    do {
      let res = await this.fetchResources(
        locations,
        page++,
        per_page,
        start_timestamp,
        end_timestamp
      );
      let loadedLocations = _get(res, "data.data.organization.locations");
      let newLocations = [];
      loadedLocations.data.map((location) => {
        if (location.resources.total == 0) {
          return;
        }
        if (!counters[location.location_id]) {
          counters[location.location_id] = {
            location_id: location.location_id,
            resources: [],
            count: 0,
          };
        }
        counters[location.location_id].total = location.resources.total;
        counters[location.location_id].count += location.resources.data.length;
        location.resources.data.map((resource) => {
          resource.location_id = location.location_id; // Location is not coming from the app
          resource.score = 0; // Everybody get scores zero at the beginning (calculated by the score algorithm)
          resource.score_assists = {}; // Score breakdown
          resource.timeblocks = {}; // Time blocks available for that space (calculated by the score algorithm)
          resources.push(resource);
        });
        if (
          counters[location.location_id].total >
          counters[location.location_id].count
        ) {
          newLocations.push(location.location_id);
        }
      });
      locations = newLocations;
    } while (rounds-- > 0 && locations.length);
    return resources;
  },

  /**
   * Fetch a page of resources for each space
   * @param {*} locations
   * @param {*} page
   * @param {*} limit
   * @param {*} start_timestamp
   * @param {*} end_timestamp
   */
  async fetchResources(locations, page, limit, start_timestamp, end_timestamp) {
    return await this.ws.graphQL(
      `query base(
        $subdomain: String!
        $locations: [ID!]
        $page: Int
        $limit: Int
        $start_timestamp: Int
        $end_timestamp: Int
      ) {
        organization(subdomain: $subdomain) {
          locations(location_id: $locations) {
            total
            data {
              location_id
              resources(limit: $limit, page: $page) {
                total
                data {
                  resource_id
                  title
                  capacity
                  price_hour
                  price_day
                  conditional_pricing {
                    conditional_pricing_id
                    name
                    prices {
                      price
                      price_max
                      unit {
                        type
                      }
                    }
                  }
                  max_booking_duration_sec
                  min_booking_duration_sec
                  max_advance_booking_sec
                  min_advance_booking_sec
                  is_bookable_outside_service_hours
                  images {
                    url
                  }
                  type {
                    name
                    time_selection_type
                    time_within_week_days {
                      name
                      day_of_week
                      start_time
                      end_time
                    }
                  }
                  amenities {
                    amenity_id
                    name
                  }
                  availability(
                    input: {
                      start_timestamp: $start_timestamp
                      end_timestamp: $end_timestamp
                      source: "Drop-in"
                    }
                  ) {
                    start_timestamp
                    end_timestamp
                  }
                }
              }
            }
          }
        }
      }`,
      {
        subdomain: this.store.state.subdomain,
        locations: locations,
        page,
        limit,
        start_timestamp: alignSeconds(start_timestamp),
        end_timestamp: alignSeconds(end_timestamp),
      }
    );
  },

  /**
   * Fetch one resource
   * @param {Number} resource_id
   * @param {*} filters
   */
  async fetchResource(resource_id, filters) {
    let { start_timestamp, end_timestamp } = this.calculateStartEnd(filters);
    let resource = await this.ws.graphQL(
      `query m(
        $subdomain: String!
        $resource_id: [ID!]
        $start_timestamp: Int
        $end_timestamp: Int
      ) {
        organization(subdomain: $subdomain) {
          locations {
            total
            data {
              location_id
              resources(resource_id: $resource_id) {
                total
                data {
                  resource_id
                  title
                  capacity
                  price_hour
                  price_day
                  conditional_pricing {
                    conditional_pricing_id
                    name
                    prices {
                      price
                      price_max
                      unit {
                        type
                      }
                    }
                  }
                  description
                  max_booking_duration_sec
                  min_booking_duration_sec
                  max_advance_booking_sec
                  min_advance_booking_sec
                  is_bookable_outside_service_hours
                  images {
                    url
                  }
                  type {
                    name
                    time_selection_type
                    time_within_week_days {
                      name
                      day_of_week
                      start_time
                      end_time
                    }
                  }
                  amenities {
                    amenity_id
                    name
                  }
                  availability(
                    input: {
                      start_timestamp: $start_timestamp
                      end_timestamp: $end_timestamp
                      source: "Drop-in"
                    }
                  ) {
                    start_timestamp
                    end_timestamp
                  }
                }
              }
            }
          }
        }
      }`,
      {
        subdomain: this.store.state.subdomain,
        resource_id,
        start_timestamp: alignSeconds(start_timestamp),
        end_timestamp: alignSeconds(end_timestamp),
      }
    );
    let location = _find(
      _get(resource, "data.data.organization.locations.data", []),
      (location) => location.resources.total == 1
    );
    if (location) {
      let resource = location.resources.data[0];
      resource.location_id = location.location_id;
      return resource;
    }
  },

  /**
   * Recalculate timeblocks (for possible options) and create a score for each resource (for sorting purposes)
   * @param {*} resources
   * @param {*} filters
   */
  async scoreResources(resources, filters, preferences) {
    /*
     * timeblocksForDayOnly: Makes the scoring function to create all timeslots for the day, but none for other days (used at resource screen)
     */
    let prefs = preferences || { timeblocksForDayOnly: false };
    let current_timestamp = alignSeconds(DateTime.local().toSeconds());
    let detectedTimezone = DateTime.local().zone.name;
    // Map the location search data, each location has a different timezone and possibly different calendars
    let locations = {};
    this.store.state.availableLocations.map((location) => {
      let search_timestamp = Math.floor(
        DateTime.fromISO(`${filters.initialDate}T${filters.initialTime}:00`, {
          zone: location.timezone,
        }).toSeconds()
      );
      let end_of_day = Math.floor(
        DateTime.fromISO(`${filters.initialDate}T23:59:59`, {
          zone: location.timezone,
        }).toSeconds()
      );
      // Align to the next 15 minutes
      search_timestamp = alignSeconds(search_timestamp);
      // if (search_timestamp < current_timestamp) {
      //     search_timestamp = current_timestamp;
      // }
      locations[location.location_id] = {
        timezone: location.timezone,
        search_timestamp,
        end_of_day,
      };
    });
    // Minimum duration for bookings on location/venue
    let minimumAllowedDuration = 15 * 60; // TODO grab this from the venue or location
    let minimumDurationSelected =
      filters.initialDuration || minimumAllowedDuration; // Minimum duration, 15 minutes
    // Stats for normalization / scoring
    let stats = {
      total: 0,
      fit_count: 0,
      deal_breaker_count: 0,
      misalign_count: 0,
      timeblocks_count: 0,
      timeblocks_min: null,
      timeblocks_max: null,
      timeblocks_diff: null,
      far_from_min: null,
      far_from_max: null,
      far_from_diff: null,
      length_from_start_min: null,
      length_from_start_max: null,
      length_from_start_diff: null,
      price_min: null,
      price_max: null,
      price_diff: null,
    };
    //window.console.log(filters, locations);
    // Process all the rooms
    resources.map((resource) => {
      const selectByTimeslot =
        resource.type.time_selection_type === "TIME_SLOTS";

      // Exclude rooms that do not handle the capacity
      //window.console.log(filters, resource.capacity);
      resource.score_assists = {};
      // Reset the timeblocks
      resource.timeblocks = [];
      // Days ahead available
      resource.days_ahead = {};

      // A deal breaker will receive a hard score penalty
      let deal_breaker = false;

      // Deal breaker based on capacity
      if (
        filters.minimumCapacity > 0 &&
        resource.capacity < filters.minimumCapacity
      ) {
        resource.score_assists.capacity = -1;
        deal_breaker = true;
      } else {
        resource.score_assists.capacity =
          resource.capacity == filters.minimumCapacity ? 1 : 0;
      }

      // Get location things
      let location = locations[resource.location_id];

      resource.score_assists.timezone =
        detectedTimezone == location.timezone ? 1 : 0;

      // Figure the minimum block allowed
      // TODO: consider the minimum time allowed by the resource (get the bigger amount)
      let minimumBookingDuration = minimumDurationSelected;

      if (
        resource.min_booking_duration_sec > 0 &&
        resource.min_booking_duration_sec > minimumBookingDuration
      ) {
        minimumBookingDuration = resource.min_booking_duration_sec;
      }

      // Deal breakers based on price
      if (
        filters.initialDuration &&
        resource.max_booking_duration_sec &&
        filters.initialDuration > resource.max_booking_duration_sec
      ) {
        resource.score_assists.max_booking_duration = -1;
        deal_breaker = true;
      }
      if (
        filters.initialDuration &&
        resource.min_booking_duration_sec &&
        filters.initialDuration < resource.min_booking_duration_sec
      ) {
        resource.score_assists.min_booking_duration = -1;
        deal_breaker = true;
      }

      // Price score
      let price_hour = minimumBookingDuration * resource.price_hour;
      let price_day = resource.price_day;
      if (price_day > 0 && price_day < price_hour) {
        resource.price = price_day;
      } else {
        resource.price = price_hour;
      }
      resource.score_assists.price = resource.price;

      // Search by the perfect time
      let availabilities = resource.availability;
      let max = availabilities.length;

      resource.score_assists.timeblocks = 0;

      // Cannot start the booking timeblocks before this
      const minimumStartAllowed = Math.max(
        selectByTimeslot
          ? location.search_timestamp
          : location.search_timestamp - 15 * 60 * 2,
        current_timestamp
      );

      // How far from the perfect fit
      resource.score_assists.far_from = -1;

      // Scan the availability to generate the time offers
      // ALERT! Considering that availability is sorted
      let found = false;
      for (var perfectIndex = 0; perfectIndex < max; perfectIndex++) {
        let period = availabilities[perfectIndex];
        if (minimumStartAllowed >= period.end_timestamp) {
          // Past, exclude
          continue;
        }
        let length_from_period = period.end_timestamp - period.start_timestamp;
        if (length_from_period < minimumBookingDuration) {
          // Do not fit, exclude
          continue;
        }

        // Length from start (easy to extend a booking?)
        let length_from_start =
          period.end_timestamp - location.search_timestamp;

        if (!found) {
          // Do not pollute stats for score if found it before
          if (length_from_start < minimumBookingDuration) {
            // Fit but if starting early than expected, should have a penalty?
            resource.score_assists.misalign = 1;
            stats.misalign_count++;
          }

          resource.score_assists.length_from_start = length_from_start;

          // Fit exactly in this slot?
          if (
            location.search_timestamp >= period.start_timestamp &&
            location.search_timestamp <= period.end_timestamp
          ) {
            resource.score_assists.fit = 1;
            stats.fit_count++;
          } else {
            resource.score_assists.fit = 0;
          }
        }

        // Generate the time blocks per sequence, making sure it's not too old
        const minimumBooking = Math.max(
          period.start_timestamp,
          minimumStartAllowed
        );

        // Calculate blocks based on each type of selection

        // For free select generate the most promising options
        if (!selectByTimeslot) {
          for (
            let timestamp = minimumBooking;
            timestamp <= period.end_timestamp;
            timestamp += 15 * 60
          ) {
            let max_length = period.end_timestamp - timestamp;
            if (
              minimumBookingDuration <= max_length &&
              timestamp >= minimumStartAllowed
            ) {
              // Account the days ahead for this specific timeblock
              let days_ahead = Math.ceil(
                (timestamp - location.end_of_day + 1) / 24 / 60 / 60
              );
              if (days_ahead > 0) {
                resource.days_ahead[days_ahead] = days_ahead;
              }
              // Stop calculating/storing timeblocks if found it before
              if (
                (!found && !prefs.timeblocksForDayOnly) ||
                (prefs.timeblocksForDayOnly && days_ahead == 0)
              ) {
                resource.timeblocks.push({
                  start_timestamp: timestamp,
                  max_length,
                  type: "FREE_SELECT",
                });
                // Set stats and info for sorting
                resource.score_assists.timeblocks++;
                stats.timeblocks_count++;
                if (timestamp == location.search_timestamp) {
                  resource.score_assists.far_from = 0;
                } else if (
                  timestamp > location.search_timestamp &&
                  resource.score_assists.far_from == -1
                ) {
                  resource.score_assists.far_from =
                    timestamp - location.search_timestamp;
                }
              }
            }
            // Do not generate more than 10 blocks
            if (
              !prefs.timeblocksForDayOnly &&
              resource.score_assists.timeblocks > 10
            ) {
              found = true;
            }
          }
        } else {
          // for time slot generate based on available time slots

          // window.console.log("Resource ", resource.resource_id, resource);
          let periodStart = DateTime.fromSeconds(minimumBooking, {
            zone: location.timezone,
          });
          const dayOfWeek = {
            1: "MONDAY",
            2: "TUESDAY",
            3: "WEDNESDAY",
            4: "THURSDAY",
            5: "FRIDAY",
            6: "SATURDAY",
            7: "SUNDAY",
          }[periodStart.weekday];
          let daySlots = resource.type.time_within_week_days.filter((ts) => {
            return ts.day_of_week === dayOfWeek;
          });
          // window.console.log("Slots for ", resource.resource_id, daySlots, period, location.timezone);
          daySlots.map((day) => {
            let start = day.start_time.split(":");
            let end = day.end_time.split(":");
            let timeslot_start_timestamp = periodStart
              .set({ hour: start[0], minute: start[1] })
              .toSeconds();
            let timeslot_end_timestamp = periodStart
              .set({ hour: end[0], minute: end[1] })
              .toSeconds();
            //window.console.log(day.start_time, day.end_time, timeslot_start_timestamp, timeslot_end_timestamp);
            if (
              period.start_timestamp <= timeslot_start_timestamp &&
              period.end_timestamp >= timeslot_end_timestamp
            ) {
              let days_ahead = Math.ceil(
                (timeslot_start_timestamp - location.end_of_day + 1) /
                  24 /
                  60 /
                  60
              );
              if (days_ahead > 0) {
                if (prefs.timeblocksForDayOnly) {
                  return;
                }
                resource.days_ahead[days_ahead] = days_ahead;
              }

              resource.timeblocks.push({
                start_timestamp: timeslot_start_timestamp,
                max_length: timeslot_end_timestamp - timeslot_start_timestamp,
                type: "TIME_SLOT",
                name: day.name,
              });

              // Set stats and info for sorting
              resource.score_assists.timeblocks++;
              stats.timeblocks_count++;
              if (timeslot_start_timestamp == location.search_timestamp) {
                resource.score_assists.far_from = 0;
              } else if (
                timeslot_start_timestamp > location.search_timestamp &&
                resource.score_assists.far_from == -1
              ) {
                resource.score_assists.far_from =
                  timeslot_start_timestamp - location.search_timestamp;
              }
            }
          });
        }
      }

      // Cannot sell
      if (deal_breaker) {
        stats.deal_breaker_count++;
        resource.score_assists.deal_breaker = 1;
        return;
      }

      //window.console.log( 'timeblocks', resource.timeblocks, 'scores', resource.score_assists);
      //window.console.log(resource.score_assists);

      // Dummy test
      //resource.score = Math.floor(Math.random() * 100);

      if (stats.total++ == 0) {
        stats.timeblocks_min = resource.score_assists.timeblocks || 0;
        stats.timeblocks_max = resource.score_assists.timeblocks || 0;
        stats.far_from_min = resource.score_assists.far_from || 0;
        stats.far_from_max = resource.score_assists.far_from || 0;
        stats.length_from_start_min =
          resource.score_assists.length_from_start || 0;
        stats.length_from_start_max =
          resource.score_assists.length_from_start || 0;
        stats.price_min = resource.score_assists.price || 0;
        stats.price_max = resource.score_assists.price || 0;
      } else {
        stats.timeblocks_min = Math.min(
          resource.score_assists.timeblocks,
          stats.timeblocks_min
        );
        stats.timeblocks_max = Math.max(
          resource.score_assists.timeblocks,
          stats.timeblocks_max
        );
        stats.far_from_min = Math.min(
          resource.score_assists.far_from,
          stats.far_from_min
        );
        stats.far_from_max = Math.max(
          resource.score_assists.far_from,
          stats.far_from_max
        );
        stats.length_from_start_min = Math.min(
          resource.score_assists.length_from_start,
          stats.length_from_start_min
        );
        stats.length_from_start_max = Math.max(
          resource.score_assists.length_from_start,
          stats.length_from_start_max
        );
        stats.price_min = Math.min(
          resource.score_assists.price,
          stats.price_min
        );
        stats.price_max = Math.max(
          resource.score_assists.price,
          stats.price_max
        );
      }
    });

    // Get the diffs
    stats.timeblocks_diff = stats.timeblocks_max - stats.timeblocks_min;
    stats.far_from_diff = stats.far_from_max - stats.far_from_min;
    stats.length_from_start_diff =
      stats.length_from_start_max - stats.length_from_start_min;
    stats.price_diff = stats.price_max - stats.price_min;

    //window.console.log(stats);

    const POINTS_FOR_NO_CAPACITY = -1;
    const POINTS_FOR_CAPACITY = 1;
    const POINTS_FOR_FIT = 1000;
    const POINTS_FOR_FIT_EARLY = -200;
    const POINTS_PER_BLOCK_SIZE = 100;
    const POINTS_FOR_TIMEZONE = 300; // Nothing for now
    const POINTS_FAR_FROM = 1000;
    const POINTS_PRICE = 200;
    const POINTS_FOR_MISSING_TIMEBLOCKS = -5000;

    resources.map((resource) => {
      let score = 0;
      let sa = resource.score_assists;
      if (sa.deal_breaker) {
        score = -100000000;
        if (sa.capacity == -1) {
          score -= POINTS_FOR_NO_CAPACITY;
        }
      }
      if (sa.timezone) {
        score += sa.timezone_points = POINTS_FOR_TIMEZONE;
      }
      if (sa.capacity) {
        score += sa.capacity_points = POINTS_FOR_CAPACITY;
      }
      if (sa.fit) {
        score += sa.fit_points = POINTS_FOR_FIT;
      }
      if (sa.misalign) {
        score += sa.misalign_points = POINTS_FOR_FIT_EARLY;
      }
      if (sa.timeblocks == 0) {
        score += POINTS_FOR_MISSING_TIMEBLOCKS;
      }

      if (!sa.deal_breaker) {
        // Bigger blocks
        if (stats.length_from_start_diff > 0) {
          score += sa.length_from_start_points =
            ((sa.length_from_start - stats.length_from_start_min) /
              stats.length_from_start_diff) *
            POINTS_PER_BLOCK_SIZE;
        }
        if (stats.far_from_diff > 0) {
          score += sa.far_from_points =
            (1 - (sa.far_from - stats.far_from_min) / stats.far_from_diff) *
            POINTS_FAR_FROM;
        }
        if (stats.price_diff > 0) {
          score += sa.price_points =
            (1 - (sa.price - stats.price_min) / stats.price_diff) *
            POINTS_PRICE;
        }
      }
      resource.score = score;
      //window.console.log(resource.title,score, sa);
    });
    return stats;
  },

  async startSetupPaymentMethod() {
    return await this.ws.graphQL(
      `mutation startSetupPaymentMethod($organization_id: ID!) {
                startSetupPaymentMethod(organization_id: $organization_id){
                  secret
                }
              }`,
      { organization_id: this.store.state.organization.organization_id },
      "startSetupPaymentMethod"
    );
  },

  async completeSetupPaymentMethod(payload) {
    return await this.ws.graphQL(
      `mutation completeSetupPaymentMethod(
        $member_id: ID
        $organization_id: ID
        $payment_method_id: String!
        $setup_intent_id: String!
      ) {
        completeSetupPaymentMethod(
          account: { member_id: $member_id, organization_id: $organization_id }
          paymentMethod: {
            payment_method: $payment_method_id
            setup_intent: $setup_intent_id
          }
        ) {
          id
          type
          card_brand
          card_country
          card_exp_year
          card_funding
          card_exp_month
          card_last_four_digits
        }
      }
      `,
      payload,
      "completeSetupPaymentMethod"
    );
  },
  async retainablePaymentMethods(payload) {
    return await this.ws.graphQL(
      `query retainablePaymentMethods($organization_id: ID!) {
        retainablePaymentMethods(organization_id:$organization_id){
          name
          open_using_external_app
          url
        }
      }`,
      payload,
      "retainablePaymentMethods"
    );
  },
  async bookingDraft(payload) {
    return await this.ws.graphQL(
      `query bookingsDraft(
        $member_id: ID!
        $user_id: ID!
        $resource_id: ID!
        $start_timestamp: Int!
        $end_timestamp: Int!
        $charge: PaymentChargeMode!
      ) {
        bookingsDraft(
          input: {
            account: {
              member_id: $member_id
            }
            owner_user_id: $user_id
            source: "Drop-in"
            bookings: [{
              resource_id: [$resource_id]
              start_timestamp: $start_timestamp
              end_timestamp: $end_timestamp
              payment: {
                calculate: NO_ALLOWANCE
                charge: $charge
              }
            }]
          }
        ) {
          booking_session_id
          bookings {
            booking_id
            start_timestamp
            end_timestamp
            is_confirmed
            is_canceled
            payment {
              quantity
              unit_amount
              price_description
              tax_rate
              inclusive_tax_rate
              tax
              included_tax
              total
            }
          }
        }
      }`,
      payload,
      "bookingsDraft"
    );
  },

  async bookingCommit(payload) {
    return await this.ws.graphQL(
      `mutation bookingsCommit(
        $member_id: ID!
        $user_id: ID!
        $resource_id: ID!
        $start_timestamp: Int!
        $end_timestamp: Int!
        $pm_id: ID
        $charge: PaymentChargeMode!
      ) {
        bookingsCommit(
          input: {
            account: {
              member_id: $member_id
            }
            owner_user_id: $user_id
            source: "Drop-in"
            bookings: [{
              resource_id: [$resource_id]
              start_timestamp: $start_timestamp
              end_timestamp: $end_timestamp
              payment: {
                calculate: NO_ALLOWANCE
                charge: $charge
                pm_id: $pm_id
              }
            }]
          }
        ) {
          booking_session_id
          bookings {
            booking_id
            start_timestamp
            end_timestamp
            is_confirmed
            is_canceled
            payment {
              quantity
              unit_amount
              price_description
              tax_rate
              inclusive_tax_rate
              tax
              included_tax
              total
            }
          }
        }
      }`,
      payload,
      "bookingsCommit"
    );
  },

  async getBookingLink(booking_id) {
    return await this.ws.graphQL(
      `query booking ($booking_id: ID!) {
                booking(booking_id: $booking_id) {
                  booking_id
                  web_link
                  is_canceled
                }
              }`,
      {
        booking_id,
      },
      "booking"
    );
  },

  async getBookingUsingJWT(jwt) {
    return await this.ws.graphQL(``, {}, "booking", {
      data: {},
      headers: {
        Authorization: "Bearer " + jwt,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  },

  async cancelBookingUsingJWT(jwt) {
    return await this.ws.graphQL(``, {}, "booking", {
      data: "action=cancel",
      headers: {
        Authorization: "Bearer " + jwt,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  },

  /*
        Tour actions
    */
  async getTourUsingJWT(jwt) {
    return await this.ws.graphQL(``, {}, "tour", {
      data: {},
      headers: {
        Authorization: "Bearer " + jwt,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  },

  async cancelTourUsingJWT(jwt) {
    return await this.ws.graphQL(``, {}, "cancelTour", {
      data: "action=cancel",
      headers: {
        Authorization: "Bearer " + jwt,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  },

  async createTour(payload) {
    return await this.ws.graphQL(
      `mutation tourCreate(
              $organization_id: ID!
              $location_id: ID!
              $email: String!
              $name: String!
              $surname: String!
              $phone: String!
              $company: String!
              $start_timestamp: Int!
              $end_timestamp: Int!
              $recaptcha_token: String!
            ) {
              tourCreate(
                organization_id: $organization_id
                location_id: $location_id
                email: $email
                name: $name
                surname: $surname
                phone: $phone
                company: $company
                start_timestamp: $start_timestamp
                end_timestamp: $end_timestamp
                recaptcha: { token: $recaptcha_token }
              ) {
                tour_id
                start_timestamp
                end_timestamp
                is_canceled
                created_timestamp
                web_link
                user {
                  fullname
                  email
                  company
                  phone
                }
                location {
                  location_id
                  name
                  timezone
                  address
                  country
                  region
                  city
                  unit
                  neighborhood
                  directions
                  phone
                  latitude
                  longitude
                  contact {
                    fullname
                    email
                  }
                }
              }
            }`,
      payload,
      "tourCreate"
    );
  },

  /**
   * Load month service hours for a specific location
   * @param {*} subdomain
   * @param {*} location_id
   * @param {*} timezone
   * @param {*} month
   */
  async loadMonth(subdomain, location_id, timezone, month) {
    // month = YYYY-MM
    if (!location_id || !month) {
      return {};
    }

    const start = DateTime.fromISO(month, { zone: timezone });
    const end = start.plus({ month: 1 }).plus({ second: -1 });

    let start_timestamp = parseInt(start.toSeconds(), 10);
    let end_timestamp = parseInt(end.toSeconds(), 10);

    let periods = await this.ws.graphQL(
      `query service_hours ($subdomain: String!
                $location_id: ID!
                $start_timestamp: Int!
                $end_timestamp: Int!) {
                organization(subdomain: $subdomain) {
                  locations(location_id: [$location_id]) {
                    data {
                      tour_availability(start_timestamp:$start_timestamp, end_timestamp: $end_timestamp) {
                        start_timestamp
                        end_timestamp
                      }
                    }
                  }
                }
              }`,
      {
        subdomain,
        location_id,
        start_timestamp: start_timestamp,
        end_timestamp: end_timestamp,
      },
      "service_hours"
    );

    periods = _find(
      _get(periods, "data.data.organization.locations.data", []),
      (location) => location.tour_availability.length > 0
    );

    let days = {};
    if (periods) {
      _forEach(periods.tour_availability, (period) => {
        // Split period in many days in case they overlap days
        let subperiods = [period];
        let loop_protection = 0;
        while (subperiods.length > 0) {
          if (loop_protection++ > 500) {
            // Loop?
            break;
          }
          let calculated_period = subperiods.shift();
          let start_day = DateTime.fromSeconds(
            parseInt(calculated_period.start_timestamp, 10),
            { zone: timezone }
          )
            .toISO()
            .substr(0, 10);
          let end_day = DateTime.fromSeconds(
            parseInt(calculated_period.end_timestamp, 10),
            { zone: timezone }
          )
            .toISO()
            .substr(0, 10);
          if (start_day != end_day) {
            let newPeriod = {
              start_timestamp: 0,
              end_timestamp: calculated_period.end_timestamp,
            };
            calculated_period.end_timestamp = parseInt(
              DateTime.fromISO(start_day + "T23:59:59", {
                zone: timezone,
              }).toSeconds() + 1,
              10
            );
            newPeriod.start_timestamp = calculated_period.end_timestamp;
            if (newPeriod.start_timestamp < newPeriod.end_timestamp) {
              subperiods.push(newPeriod);
            }
          }
          if (!days[start_day]) {
            days[start_day] = [];
          }
          days[start_day].push(calculated_period);
        }
      });
    }
    return days;
  },
};
