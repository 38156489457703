var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showTopBar)?_c('div',{staticClass:"top-bar-wrapper",class:{ 'fullscreen-mode': _vm.mode == 'fullscreen' }},[_c('div',{staticClass:"top-bar",class:{
      'fullscreen-mode': _vm.mode == 'fullscreen',
      'has-border': _vm.hasBorder,
      'is-fixed': _vm.isFixed,
    }},[(!_vm.showMobileBar)?_c('div',{staticClass:"layout-content"},[_c('div',{staticClass:"logo"},[(_vm.logo)?_c('img',{staticClass:"clickable",attrs:{"src":_vm.logo,"srcset":_vm.logoSrcSet},on:{"click":function($event){return _vm.$emit('backToStart')}}}):_vm._e()]),_c('div',{staticClass:"contact"},[(_vm.contactUsEmail)?_c('base-button',{attrs:{"outlined":true,"monocrome":true},on:{"click":function($event){return _vm.openContact()}}},[_vm._v("Contact us")]):_vm._e()],1)]):_vm._e(),(_vm.showMobileBar)?_c('div',{staticClass:"layout-content"},[(_vm.showCloseButton)?_c('o-btn',{staticClass:"padded-button default-font-color",attrs:{"icon":""},on:{"click":_vm.backActionClick}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.showBackButton)?_c('o-btn',{staticClass:"padded-button default-font-color",attrs:{"icon":""},on:{"click":_vm.backActionClick}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e(),(_vm.showSummary)?_c('div',{staticClass:"summary",on:{"click":function($event){return _vm.$emit('summaryClick')}}},[_c('v-img',{attrs:{"src":_vm.$optix.utils.resizedImage(
              _vm.selectedBooking.resource.images[0].url,
              36,
              36
            ),"height":"36","width":"36"}}),_c('div',{staticClass:"text clickable"},[_vm._v(" Show summary "),_c('v-icon',{staticClass:"default-font-color"},[_vm._v("mdi-menu-down")])],1),(_vm.selectedBooking.price_description)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.$optix.utils.currencyFormat(_vm.selectedBooking.total))+" ")]):_vm._e()],1):_c('div',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.pageTitle))])],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }