<template>
  <div
    class="base-button"
    @click="click($event)"
    v-ripple="true"
    :class="{ standard: $vuetify.breakpoint.smAndUp }"
  >
    <o-icon :icon="socialIcon" class="accent-colored" />
    <span v-if="$vuetify.breakpoint.smAndUp">{{ socialName }}</span>
  </div>
</template>
<script>
export default {
  props: {
    organization: {
      default: {},
    },
    socialNetwork: {
      default: "google",
    },
  },
  computed: {
    socialName() {
      return this.socialNetwork;
    },
    socialIcon() {
      switch (this.socialNetwork) {
        // case "facebook":
        //   return "o-icon-facebook";
        case "google":
          return "o-icon-google";
        case "linkedin":
          return "o-icon-linkedin";
        default:
          return false;
      }
    },
  },
  methods: {
    click() {
      window.open(
        this.$optix.env.getConf("optix_v1_url") +
          "/auth/web/social?state=" +
          this.$store.state.organization.organization_id +
          ":" +
          this.socialNetwork +
          ":dropin",
        "socialPopupWindow",
        "location=no,width=600,height=600,scrollbars=yes,top=100,left=200"
      );
    },
  },
};
</script>
<style scoped>
.base-button {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  font-weight: 600;
  text-transform: uppercase;
  background: transparent;
  color: var(--outline-dark-text);
  border: var(--outline-dark-border);
  padding: 24px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.base-button .o-icon {
  font-size: 24px;
  line-height: 24px;
}
.base-button.standard .o-icon {
  margin-left: -8px;
}
.base-button:hover {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.03);
}
</style>
