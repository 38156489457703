<template>
  <div class="base">
    <v-img
      v-if="image"
      :src="$optix.utils.resizedImage(image, 500)"
      class="room-image"
    />

    <h1 class="text-button-container">{{ booking.resource.title }}</h1>

    <location-time-block
      v-if="
        booking.location &&
          booking.period &&
          booking.period.start &&
          booking.period.end
      "
      class="ltb"
      :location="booking.location"
      :start="booking.period.start.toSeconds()"
      :end="booking.period.end.toSeconds()"
    />
    <template v-if="booking.price_description">
      <div class="price-item">
        <p>{{ booking.price_description }}</p>
        <p>{{ $optix.utils.currencyFormat(booking.subtotal) }}</p>
      </div>
      <div class="price-item" v-if="booking.tax_label">
        <p>{{ booking.tax_label }}</p>
        <p>{{ $optix.utils.currencyFormat(booking.tax) }}</p>
      </div>
      <div class="price-item total">
        <p>Total</p>
        <p>{{ $optix.utils.currencyFormat(booking.total) }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    booking: {
      default: {},
    },
  },
  computed: {
    image() {
      if (!this.booking) {
        return false;
      }
      if (!this.booking.resource.images) {
        return false;
      }
      if (this.booking.resource.images.length === 0) {
        return false;
      }
      return this.booking.resource.images[0].url;
    },
  },
  methods: {
    formatTime(datetime) {
      return datetime.toLocaleString(DateTime.TIME_SIMPLE);
    },
  },
};
</script>
<style scoped>
.base {
  border: var(--outline-dark-border);
  border-radius: 4px;
  padding: 24px;
}
.base .o-icon {
  font-size: 24px;
  line-height: 24px;
}
.room-image {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
}
h1.text-button-container button {
  float: right;
}
.ltb {
  padding: 16px 0 0 0 !important;
}
.price-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.24);
  margin-top: 16px;
  padding-top: 16px;
}
.price-item.total {
  font-weight: bold;
  font-size: 20px;
}
.price-item p {
  margin: 0px;
}
</style>
