<template>
  <o-app :class="{ tour: isTour }">
    <top-bar
      v-if="!loading && organization && organization.name"
      :backAction="toolbarBackAction"
      @backAction="toolbarActionTriggered"
      @summaryClick="showOverlaySummary = true"
      @backToStart="goBackToStart"
    />
    <div
      v-if="appEnabled"
      class="body-container"
      :class="{
        'fullscreen-mode': mode == 'fullscreen',
        'modal-mode': mode == 'modal',
        'embed-mode': mode == 'embed',
        'mt-4': noTopBar,
      }"
    >
      <!-- Main content -->
      <router-view v-if="!loading"></router-view>
      <!-- Overlay summary -->
      <transition name="fade">
        <div
          v-if="showOverlaySummary"
          class="overlay"
          @click.stop="showOverlaySummary = false"
        ></div>
      </transition>
      <transition name="slide">
        <div v-if="showOverlaySummary" class="summary-overlay">
          <overlay-summary
            :booking="selectedBooking"
            @hideClick="showOverlaySummary = false"
          />
        </div>
      </transition>
    </div>
    <div v-if="appEnabled === false" class="error-loading">
      <div class="icon">
        <img
          v-if="$optix.theme.darkMode"
          src="@/assets/onboarding-error-dark.svg"
        />
        <img v-else src="@/assets/onboarding-error-light.svg" />
      </div>
      {{ appEnabledError }}
    </div>
    <v-snackbar
      top
      v-model="generalSnackbar.open"
      :color="generalSnackbar.type"
    >
      {{ generalSnackbar.messages.join(", ") }}
      <v-btn text color="white" @click="closeSnackbar()">Close</v-btn>
    </v-snackbar>
    <powered-by-bar v-if="showPoweredBar" />
  </o-app>
</template>

<script>
import _get from "lodash/get";
import { mapMutations, mapGetters, mapState } from "vuex";
import OptixV2Service from "./services/OptixV2Service";
import OptixV1Service from "./services/OptixV1Service";
import { alignSeconds } from "./services/TimeService";
import { DateTime } from "luxon";
import { setOptions, bootstrap } from "vue-gtag";

export default {
  name: "App",

  data: () => ({
    loading: true,
    // The route used as the first page
    entryRoute: {},
    // Top overlay summary
    showOverlaySummary: false,
    showPoweredBar: false,
    subdomain: null,
    inactive: false,
  }),

  /**
   * Basic config
   */
  created() {
    // Read URL variables
    this.$optix.env.readUrl();

    switch (this.$optix.env.get.mode) {
      case "modal":
      case "embed":
        this.setMode(this.$optix.env.get.mode);
        break;
      default:
        this.setMode("fullscreen");
    }

    // Production, staging and local conf
    this.$optix.env.setConf(
      {
        google_analytics: "UA-90531668-13",
        google_key: "AIzaSyBnsJSsYKJ88acdacsHSa8vI40ylRZODWU",
        recaptcha_site_key: "6Lcp1_ocAAAAAFfK_lJA_yXPWKmlnplfKW2XCyOK",
        spreedly_key: "77SlEJt07tR7XBDp8A7DvVfYNqU",
        stripe_public_key: "pk_live_bYzwmSBY39wUDR9Q2rupqejH",
      },
      "production"
    );

    this.$optix.env.setConf(
      {
        google_analytics: "UA-90531668-12",
        google_key: "AIzaSyCjtPqdUseyvcsZKZTkZE1Bw2KF2tJ4EoA",
        optix_admin_url: "https://SUBDOMAIN.staging.optixdev.com/",
        optix_v1_url: "https://staging.catalufa.net/api",
        optix_v2_url: "https://api.catalufa.net",
        recaptcha_site_key: "6LeTyfocAAAAAEwnfgQXaS4H7T2vKfs4LHoaVWkF",
        spreedly_key: "DSms22xgi3tkaNI4Rgq6S6tMwVr",
        stripe_public_key: "pk_test_NgBDTokHSqjslkb1bOzCtLjK",
      },
      "staging"
    );

    this.$optix.env.setConf(
      {
        google_analytics: "UA-90531668-12",
        google_key: "AIzaSyB10skF7f-BQy2gPYUmzbs_9i9OkPgIDM0",
        optix_admin_url: "http://SUBDOMAIN.optixapp.local/",
        optix_v1_url: "http://sharedesk.local/api",
        optix_v2_url: "http://api.sharedesk.local",
        recaptcha_site_key: "6LeTyfocAAAAAEwnfgQXaS4H7T2vKfs4LHoaVWkF",
        spreedly_key: "DSms22xgi3tkaNI4Rgq6S6tMwVr",
        stripe_public_key: "pk_test_NgBDTokHSqjslkb1bOzCtLjK",
      },
      "local"
    );

    this.subdomain = window.location.host.replace(/\..+$/, "");

    // Detect the local environment and set it
    if (window.location.host.match(/localhost/)) {
      this.$optix.env.environment = "local";
      this.subdomain = "qa";
    } else if (window.location.host.match(/staging2\.optixdev/)) {
      this.$optix.env.environment = "staging2";
    } else if (window.location.host.match(/staging\.optixdev/)) {
      this.$optix.env.environment = "staging";
    }

    OptixV1Service.init(this.$optix.ws, this.$store);
    OptixV2Service.init(this.$optix.ws, this.$store);
    let currentDate = DateTime.fromSeconds(
      alignSeconds(DateTime.local().toSeconds())
    );
    this.setInitialFilters({
      filterInitialDate: currentDate.toFormat("yyyy-LL-dd"),
      filterInitialTime: currentDate.toFormat("HH:mm"),
    });
    this.setSubdomain(this.subdomain);
    if (
      this.$route.name == "resource" ||
      this.$route.name == "resource-picker" ||
      this.$route.name == "tour"
    ) {
      this.entryRoute = this.$route;
    } else {
      this.entryRoute = {
        name: "find",
        params: {},
      };
    }

    // Google analytics
    setOptions({
      config: {
        id: this.$optix.env.getConf("google_analytics"),
        params: {
          dimension1: this.subdomain,
          dimension2: this.mode,
          dimension3: this.entryRoute.name,
        },
      },
    });
    bootstrap().then(() => {});
  },
  watch: {
    "$route.name"() {
      // Ensure pages focus on top when routes change
      try {
        if (document.body && document.body.scrollTop) {
          document.body.scrollTop = 0;
        }
        if (document.documentElement && document.documentElement.scrollTop) {
          document.documentElement.scrollTop = 0;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
  /**
   * Loading things
   */
  async mounted() {
    // Always call init, so the app will acknowledge the ok to start
    // Subdomain and load theme makes the app load the theme set at the venue level
    let info = await this.$optix.init({
      subdomain: this.subdomain,
      loadTheme: true,
      subdomainQueryInsertion: `extra: organization(subdomain: $subdomain) {
        exclusive_tax_rate
        inclusive_tax_rate
        features {
          remove_optix_branding
          tax_mode
          tax_name
        }
        contact {
          fullname
          email
        }
        square_logo {
          url
        }
        wide_logo {
          url
        }
        dropin_booking_widget {
          title
          cancellation_policy
        }
        tour_widget {
          title
          duration_sec
        }
        terms {
          value
        }
        locations {
          data {
            location_id
            name
            timezone
            address
            country
            region
            city
            unit
            neighborhood
            directions
            phone
            latitude
            longitude
            contact {
              fullname
              email
            }
            amenities {
              amenity_id
              name
              description
            }
            resources {
              total
            }
          }
        }
      }`,
    });

    if (!info.organization || !info.extra) {
      this.inactive = true;
      this.loading = false;
      this.$optix.iAmReady();
      return;
    }

    let locations_param_list = (this.$optix.env.get.locations || "")
      .split(",")
      .filter((num) => num.length > 0);

    this.setAvailableLocations(
      _get(info, "extra.locations.data", []).filter(
        // Remove locations without public resources or non-listed in parameter
        (item) => {
          return (
            item.resources.total > 0 &&
            (locations_param_list.length == 0 ||
              locations_param_list.indexOf(item.location_id) !== -1)
          );
        }
      )
    );
    let organization = _get(info, "organization", {});
    organization.locations = _get(info, "extra.locations.data", []);
    organization.drop_in = _get(info, "extra.dropin_booking_widget", {
      title: "",
    });
    organization.contact = _get(info, "extra.contact", {});
    organization.tours = _get(info, "extra.tour_widget", { title: "" });
    organization.exclusive_tax_rate = _get(info, "extra.exclusive_tax_rate");
    organization.inclusive_tax_rate = _get(info, "extra.inclusive_tax_rate");
    organization.features = _get(info, "extra.features");

    if (!organization.features.remove_optix_branding) {
      this.showPoweredBar = true;
    }

    if (this.$route.name.match(/tour/)) {
      document.title =
        organization.tours && organization.tours.title
          ? organization.tours.title
          : "Book a tour";
    } else {
      document.title =
        organization.drop_in && organization.drop_in.title
          ? organization.drop_in.title
          : "Book a room";
    }

    const logo = _get(info, "extra.square_logo.url", false);
    if (logo) {
      this.$optix.page.changeFavicon(logo);
    }
    organization.logo = _get(info, "extra.wide_logo.url", logo);
    organization.custom_terms = _get(info, "extra.terms.value", "");

    this.$gtag.set({
      currency: this.organization.currency,
    });

    this.setOrganization(organization);

    this.$optix.page.pushTheme({
      formInputRounded: false,
    });

    this.$vuetify.theme.dark = this.$optix.theme.darkMode;

    this.loading = false;
    // Notify that the app is ready
    this.$optix.iAmReady();
  },
  computed: {
    ...mapState(["generalSnackbar", "organization", "mode"]),
    ...mapGetters(["selectedBooking"]),
    toolbarBackAction() {
      if (
        this.$route.name == this.entryRoute.name ||
        this.$route.name == "confirmation" ||
        this.$route.name == "tour-confirmation"
      ) {
        return "close";
      } else {
        return "back";
      }
    },
    appEnabled() {
      if (this.inactive) {
        return false;
      }
      if (!this.loading && this.organization && this.organization.name) {
        switch (this.$route.name) {
          case "confirmation":
          case "tour-confirmation":
            return true;
          case "find":
          case "resource":
          case "resource-picker":
            return !!this.organization.drop_in;
          case "tour":
            return !!this.organization.tours;
          default:
            return true;
        }
      }
      return null;
    },
    appEnabledError() {
      return (
        "Please contact us to book a " +
        (this.$route.name.match(/^tour/) ? "tour" : "space")
      );
    },
    noTopBar() {
      if (this.mode == "embed" && this.$route.name == "confirmation") {
        return true;
      }
      if (this.mode == "embed" || this.mode == "modal") {
        switch (this.entryRoute.name) {
          case "resource-picker":
          case "resource":
            return this.entryRoute.name == this.$route.name;
          default:
            false;
        }
      }
      return false;
    },
    isTour() {
      return (
        this.$route.name.match(/^tour/) &&
        this.mode != "embed" &&
        this.mode != "modal" &&
        this.appEnabled
      );
    },
  },
  methods: {
    toolbarActionTriggered() {
      if (this.toolbarBackAction == "back") {
        switch (this.$route.name) {
          // case "complete":
          // case "password":
          //   this.$router.push({ name: "auth" });
          //   break;
          case "confirmation":
            // Reset current state
            var currentDate = DateTime.fromSeconds(
              alignSeconds(DateTime.local().toSeconds())
            );
            this.setInitialFilters({
              filterInitialDate: currentDate.toFormat("yyyy-LL-dd"),
              filterInitialTime: currentDate.toFormat("HH:mm"),
            });
            this.$router.push({ name: "find" });
            break;
          // case "passwordRecovery":
          //   this.$router.push({ name: "password" });
          // break;
          default:
            this.$router.go(-1);
        }
      }
      if (this.toolbarBackAction == "close") {
        window.parent.postMessage("closeOptixModal", "*");
      }
    },
    goBackToStart() {
      if (this.$route.name != this.entryRoute.name) {
        this.$router.push({
          name: this.entryRoute.name,
          params: this.entryRoute.params,
        });
      }
    },
    ...mapMutations([
      "setAvailableLocations",
      "setSubdomain",
      "setInitialFilters",
      "setOrganization",
      "setMode",
      "closeSnackbar",
    ]),
  },
};
</script>
<style scoped>
.body-container > * {
  max-width: 1200px;
  width: 100%;
}
pre {
  font-size: 10px;
}
div.columns {
  display: flex;
  flex-direction: row;
}
div.columns > div {
  padding: 16px;
}
div.example {
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.example > .rendered {
  padding-left: 30px;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 88;
  background: rgba(0, 0, 0, 0.6);
}

.summary-overlay {
  background: var(--optix-theme-backgroundColor);
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 16px;
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  z-index: 100;
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: top 0.3s ease-out;
}

/*
you set the css property before transition starts
*/
.slide-enter,
.slide-leave-to {
  top: -100%;
}

/*
you set the css property it will be when transition ends
*/
.slide-enter-to,
.slide-leave {
  top: 0px;
}

.error-loading {
  text-align: center;
  padding: 30px;
  color: #888;
}
.error-loading .icon {
  margin-right: -35px;
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
